import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { FormControl } from '@angular/forms';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { WidgetService } from 'src/app/widgets/services/widget.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/pages/contacts/services/contact.service';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';


@Component({
  selector: 'advfilterQuote',
  templateUrl: './advancefilter.component.html',
  styleUrls: ['./advancefilter.component.scss']
})
export class AdvancefilterQuoteComponent implements OnInit {
  @Output() getFilterList: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('advfMenu') advfMenu: MatMenuTrigger;
  itemCount = 10;
  currentPage:any=0
  globalSearchText: any = '';
  sortField: any = 'created_at';
  sortOrder: any = 'DESC';
  where: any;
  savefiltercolor: any;
  merContactListArr:any;
  userMerchants: any;
  sliderValue: number = 0;
  serchArr: any = {};
  sliderMaxValue: number = 0;
  selectable = true;
  removable = true;
  page: any = {
    currentPage: 1,
    pageSize: 10,
  }
  allOrgArr:any;
  statusDummyArr: any[] = [];
  actionSearchTags: string = '';
  statusSearchTags: string = '';
  organizationSearchTags: string = '';
  contactSearchTags: string = '';
  actionArr;
  deletedQuotes:boolean=false
  invoices:boolean=false
  sliderOptions: Options = {
    floor: 0,
    ceil: 1000000,
    translate: (value: number, label: LabelType): string => {
      //this.savedFilter.slider_value = this.sliderValue + '-' + this.sliderMaxValue;
      switch (label) {
        case LabelType.Low:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
        case LabelType.High:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
        default:
          return this.getCurrency() + ' ' + new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(value);
      }
    }
  };
  savedFilter: any = {};
  singale_value = new FormControl();
  deal_typeArr: any[] = [];
  deal_stageArr: any[] = [];
  activityArr: any[] = [];
  @Input() tagsArr: any[] = [];
  @Input() allTagsArr: any[] = [];
  @Input() customerCustomFieldArr: any[] = [];
  @Input() dataSource: any [];
  stageDummyArr: any[] = [];
  activityDummyArr: any[] = [];
  orgDummyArr: any[] = [];
  conDummyArr: any[] = [];

  tagsDummyArr: any[] = [];
  customFieldDummyArr: any[] = [];
  viewData: any;
  totalCount: number;
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  deactivatedleads: any;
  rotted_days: any;
  deal_status: any;

  constructor(
    public leadService: LeadService,
    public WidgetService: WidgetService,
    private translateService: TranslateService,
    public contactService: ContactService,
    public QuoteTemplateServices: QuoteTemplateService,


  ) { }

  ngOnInit(): void {
    console.log(this.dataSource,'llllllllll1')
    let applied=JSON.parse(sessionStorage.getItem("appliedFilters"));
    if(applied){
      this.savedFilter=applied;

    }else{
      this.savedFilter={}
    }

    console.log("applied",applied);
    this.getViews();
    this.getOrgList()
    this.getActtionList()
    this.getMerchantCustomer()
    this.userMerchants = JSON.parse(localStorage.getItem('user_merchants'));
    this.leadService.activeTabs$.subscribe((res) => { this.deal_status = res; });
    
  }

  getCurrency() { return this.userMerchants[0]?.merchant_details?.currencies?.currency_symbol || '' }

  getAccorValue(event: any, value: any, from: any) {
    if (from === 'organization') {
      if (event?.checked) {
        console.log(value,'ttttttttttt')
        this.orgDummyArr.push({ id: value?.id, name: value?.name});
        this.savedFilter['organization'] = this.orgDummyArr;
      } else {
        this.orgDummyArr?.splice(this.orgDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['organization'] = this.orgDummyArr;
      }   
     }

     if (from === 'contact') {
      if (event?.checked) {
        this.conDummyArr.push({ first_name: value?.fname, last_name: value?.lname });
        this.savedFilter['contact'] = this.conDummyArr;
        console.log(this.savedFilter,'this.savedFilter')
      } else {
        this.conDummyArr?.splice(this.conDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['contact'] = this.conDummyArr;
      }   
      
     }

    if (from === 'deal_type') {
      this.getDealStage(value?.id);
      this.savedFilter['deal_type'] = [{ id: value?.id, name: value?.name }];
    }

    if (from === 'deal_stage') {
      if (event?.checked) {
        this.stageDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['deal_stage'] = this.stageDummyArr;
      } else {
        this.stageDummyArr?.splice(this.stageDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['deal_stage'] = this.stageDummyArr;
      }
    }

    if (from === 'actions') {
      if (event?.checked) {
        this.activityDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['actions'] = this.activityDummyArr;
      } else {
        this.activityDummyArr?.splice(this.activityDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['actions'] = this.activityDummyArr;
      }
    }

    if (from === 'status') {
      if (event?.checked) {
        this.statusDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['status'] = this.statusDummyArr;
        console.log(this.savedFilter,'this.savedFilter')
      } else {
        this.statusDummyArr?.splice(this.statusDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['status'] = this.statusDummyArr;
      }
    }

    if (from === 'customFields') {
      if (event?.checked) {
        this.customFieldDummyArr.push({ id: value?.id, name: value?.name });
        this.savedFilter['customFields'] = this.customFieldDummyArr;
      } else {
        this.customFieldDummyArr?.splice(this.customFieldDummyArr?.findIndex((obj) => obj?.id === value?.id), 1);
        this.savedFilter['customFields'] = this.customFieldDummyArr;
      }
    }

    if (from === 'singale_value') {
      this.savedFilter['singale_value'] = value;
      this.sliderValue = 0;
      this.sliderMaxValue = 0;
      this.savedFilter.slider_value = null;
    }

    if (from === 'slider_value') {
      this.savedFilter['slider_value'] = (this.sliderValue && this.sliderMaxValue) ? this.sliderValue + '-' + this.sliderMaxValue : null;
      if (event || value) {
        this.singale_value.setValue(null);
        this.savedFilter.singale_value = '';
      }
    }

    if (from === 'singale_date') {
      this.savedFilter['singale_date'] = event;
      this.savedFilter.start_date = null;
      this.savedFilter.end_date = null;
      this.savedFilter.from_to_date = null;

    }

    if (value === 'fromtodate') {
      if (from === 'start_date') {
        this.savedFilter['start_date'] = event;
        this.savedFilter.singale_date = null;
      }
      if (from === 'end_date') {
        this.savedFilter['end_date'] = event;
        this.savedFilter.singale_date = null;
      }
      this.savedFilter['from_to_date'] = (this.savedFilter?.end_date != null && this.savedFilter?.start_date != null) ? (this.savedFilter?.start_date + ' to ' + this.savedFilter?.end_date) : null;
    }

    if (from === 'rotten_days') {
      this.savedFilter['show_rotten_deals'] = this.rotted_days ? this.rotted_days : 0;
    }
    if (from === 'in_active_deals') {
      this.savedFilter['show_deactivated_leads'] = this.deactivatedleads ? this.deactivatedleads : 0;
    }
    
  }

  removeFilter(id, type) {
    if (type === 'deal_type') {
      this.savedFilter?.deal_type?.splice(this.savedFilter?.deal_type?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'deal_stage') {
      this.savedFilter?.deal_stage?.splice(this.savedFilter?.deal_stage?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'actions') {
      this.savedFilter?.actions?.splice(this.savedFilter?.actions?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'organization') {
      this.savedFilter?.organization?.splice(this.savedFilter?.organization?.findIndex((obj) => obj?.id === id), 1);
    }

    if (type === 'contact') {
      this.savedFilter?.contact?.splice(this.savedFilter?.contact?.findIndex((obj) => obj?.id === id), 1);
    }
    if (type === 'status') {
      this.savedFilter?.status?.splice(this.savedFilter?.status?.findIndex((obj) => obj?.id === id), 1);
      this.tagsArr = this.tagsArr.map(e => (e.id === id ? { ...e, checked: false } : e));
    }
    if (type === 'customFields') {
      this.savedFilter?.customFields?.splice(this.savedFilter?.customFields?.findIndex((obj) => obj?.id === id), 1);
      this.customerCustomFieldArr = this.customerCustomFieldArr.map(e => (e.id === id ? { ...e, checked: false } : e));
    }
    if (type === 'singale_value') {
      this.savedFilter.singale_value = '';
      this.singale_value?.setValue(null);
    }
    if (type === 'slider_value') {
      this.sliderValue = 0;
      this.sliderMaxValue = 0;
      this.savedFilter.slider_value = null;
    }
    if (type === 'singale_date') {
      this.savedFilter.singale_date = null;
    }
    if (type === 'from_to_date') {
      this.savedFilter.from_to_date = null;
      this.savedFilter.end_date = null;
      this.savedFilter.start_date = null;
    }
    if (type === 'deletedQuotes') {
      this.savedFilter.deletedQuotes = null;
    }
    if (type === 'invoices') {
      this.savedFilter.invoices = null;
      this.invoices = false
    }
    this.deactivatedleads = 0;
    this.rotted_days = 0;
  }

  toggle(event: any, type: string) {
    if (type === 'rotten_days') {
      this.rotted_days = event?.checked ? 1 : 0;
      this.leadService.rotted_days$.next(this.rotted_days);
      this.getAccorValue('', '', 'rotten_days');
    }

    if (type === 'in_active_deals') {
      this.deactivatedleads = event?.checked ? 1 : 0;
      this.leadService.deactivatedStatus$.next(this.deactivatedleads);
      this.getAccorValue('', '', 'in_active_deals');
    }

  }

  saveFilter() {
    let payload = {
      pagination: true,
      limit: this.itemCount,
      page: 1,
      search: this.globalSearchText ? this.globalSearchText : '',
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      whereFilterData: [{
        deal_status: "",
        activity_id: [],
        deal_type_id: [],
        deal_stage_id: [],
        organization_id:[],
        contactName:[],
       
      }]
    }

    if (this.savedFilter) {

      if (this.savedFilter?.actions) {
        this.savedFilter?.actions?.forEach((ac, i) => {
          payload.whereFilterData[0]['activity_id']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.organization) {
        this.savedFilter?.organization?.forEach((ac, i) => {
          payload.whereFilterData[0]['organization_id']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.contact) {
        this.savedFilter?.contact?.forEach((ac, i) => {
          payload.whereFilterData[0]['contactName']?.push(ac?.id)
        })
      };

      if (this.savedFilter?.status) {
        let identicalTagsFilter = [];
        this.savedFilter.status.forEach(savedTag => {
          let filteredArray = this.allTagsArr
            .filter(tag => tag.name === savedTag.name)
            .map(tag => ({ name: tag.name, id: tag.id }));
          
          identicalTagsFilter = identicalTagsFilter.concat(filteredArray);
        });
        console.log(identicalTagsFilter);
        identicalTagsFilter.forEach((ac, i) => {
          if(!payload.whereFilterData[0]['tag_id'].includes(ac?.id)){
            payload.whereFilterData[0]['tag_id']?.push(ac?.id)
          }
        })
      };
      if (this.savedFilter?.customFields) {
        this.savedFilter.customFields.forEach((ac, i) => {
          const id = ac?.id;
          if (!payload.whereFilterData[0]['customField'].includes(id)) {
            payload.whereFilterData[0]['customField'].push(id);
          }
        });
      };
      if (this.savedFilter?.deal_type) {
        this.savedFilter?.deal_type?.forEach((ac, i) => {
          payload.whereFilterData[0]['deal_type_id']?.push(ac?.id)
        })
      };
      if (this.savedFilter?.deal_stage) {
        this.savedFilter?.deal_stage?.forEach((ac, i) => {
          payload.whereFilterData[0]['deal_stage_id'].push(ac?.id)
        })
      };
      if (this.savedFilter?.singale_value) {
        payload.whereFilterData[0]['single_value'] = this.savedFilter?.singale_value;
      };

      if (this.sliderValue) {
        payload.whereFilterData[0]['min_value'] = this.sliderValue;
      };
      if (this.sliderMaxValue) {
        payload.whereFilterData[0]['max_value'] = this.sliderMaxValue;
      };
      if (this.savedFilter?.singale_date) {
        payload.whereFilterData[0]['singale_date'] = this.savedFilter?.singale_date;
      };

      if (this.savedFilter?.start_date) {
        payload.whereFilterData[0]['start_date'] = this.savedFilter?.start_date;
      };
      if (this.savedFilter?.end_date) {
        payload.whereFilterData[0]['end_date'] = this.savedFilter?.end_date;
      };
    }
    payload.whereFilterData[0]['show_deactivated_leads'] = this.deactivatedleads ? this.deactivatedleads : 0;
    payload.whereFilterData[0]['show_rotten_deals'] = this.rotted_days ? this.rotted_days : 0;
    payload.whereFilterData[0]['deal_status'] = this.deal_status;
    payload.whereFilterData = [this.clean(payload?.whereFilterData[0])];
    // localStorage.setItem('leadAppliedFilter', JSON.stringify(payload.whereFilterData))

    console.log("filters list:",this.savedFilter);
    sessionStorage.setItem("appliedFilters",JSON.stringify(this.savedFilter))
    if (this.viewData === 'list_view') {
      this.getFilterList.emit(this.savedFilter)
      if (this.advfMenu) {
        this.advfMenu.closeMenu();
      }

    }

    if (this.viewData === 'grid_view') {
      console.log(this.savedFilter,'this.savedFilter')
      this.getFilterList.emit(this.savedFilter)
      if (this.advfMenu) {
        this.advfMenu.closeMenu();
      }

      // this.leadService.resetFilterleadValue$.next({ filterWhere: this.savedFilter, view:'grid_view' });
      // this.leadService.cardLayoutViewData$.next(payload?.whereFilterData);
      // this.savedFilter = {};
      // this.singale_value.setValue(null);
      // this.deactivatedleads = 0;
      // this.rotted_days = 0;
      // this.sliderValue = 0;
      // this.sliderMaxValue = 0;
      // this.savedFilter.slider_value = null;
    }
  }
  
  clean(obj) {
    for (let propName in obj) {
      if (obj[propName]?.length === 0) {
        delete obj[propName];

      }
    }
    return obj
  }

  resetFilter() {
    if (this.viewData === 'list_view') {
      this.savedFilter = {};
      sessionStorage.removeItem("appliedFilters")

      this.getFilterList.emit(this.savedFilter)
    }

    if (this.viewData === 'grid_view') {
      this.savedFilter = {};
      sessionStorage.removeItem("appliedFilters")
      this.getFilterList.emit(this.savedFilter)

    }

  }

  getActivityList() {  
    const actions = this.savedFilter?.actions?.map(action => action.name);
    this.activityArr = [
      { name: 'Accepted', checked: actions?.includes('Accepted') },
      { name: 'Rejected', checked: actions?.includes('Rejected') },
      { name: 'Deleted', checked: actions?.includes('Deleted') },
    ];
  }
  

  async getActtionList() {
    const actions = this.savedFilter?.status?.map(action => action.name);
    await this.getQuoteStages();

    // Generate array dynamically using names fetched from the API
    const dynamicActivityArr = this.actionArr?.map(item => {
        return { name: item?.name, checked: actions?.includes(item.name) };
    });

    // Assign dynamically generated array to activityArr
    this.actionArr = dynamicActivityArr;
  }

  getTags() {
    this.leadService.getTagsList().subscribe((res: any) => {
      this.tagsArr = res ? this.getUniqueTags(res.data, 'name') : null;
      this.allTagsArr =  res?.data;
    }, (error) => { });

  }

  getOrgList() {
    this.leadService.getOrganizationList().subscribe((res: any) => {
      this.allOrgArr = res?.data.map(org => ({
        ...org,
        checked: this.savedFilter?.organization?.length > 0 && this.checkIfNameMatches(org?.name)
      }));
    }, (error) => { });
  }
  
  checkIfNameMatches(name: string): boolean {
    // Check if the name exists in the savedFilter.organization array
    return this.savedFilter?.organization?.some(org => org.name === name);
  }
  

  getUniqueTags(status: any[], property: string): any[] {
    return Array.from(new Set(status?.map(tag => tag[property]))).map(name => {
      return status.find(tag => tag[property] === name);
    });
  }

  getDealType() {
    this.leadService.getDealTypeList().subscribe((res: any) => {
      this.WidgetService.dealType_List$.next(res);
      this.deal_typeArr = res ? res?.data : null;
      this.getActivityList();
      this.getTags();
    }, (error) => { });
  }

  getDealStage(id: any) {
    this.leadService.dealStageList({ deal_type_id: id }).subscribe((res: any) => {
      this.deal_stageArr = res?.data ? res?.data : null;
    })
  }

  getOrgDetail(id: any) {
    console.log(id,'kkkkkkkkkkkkkkkk')
    // this.leadService.dealStageList({ deal_type_id: id }).subscribe((res: any) => {
    //   this.deal_stageArr = res?.data ? res?.data : null;
    // })
  }

  getViews() {
    this.leadService.viewFilter$.subscribe((res: any) => {
      if (res) {
        this.viewData = res;
        if (res === 'list_view') { this.getDealType(); }
        if (res === 'grid_view') { this.getActivityList(); this.getTags(); }
      }
    })
  }

  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }

  getMerchantCustomer(data: any = '') {
    this.serchArr = {
      pagination: true,
      limit: this.page?.pageSize,
      page: this.page?.currentPage || 1,
      whereFilterData: this.where,
      search: this.globalSearchText ? this.globalSearchText : '',
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    const merchantUserListSubs = this.QuoteTemplateServices.merchantUserList({list: 1}).subscribe(res => {
      if (res.data) {
        this.currentPage = res.currentPage;
        this.merContactListArr = res.data.map((item: any) => {
          return {
            ...item,
            name:item?.fname,
            checked: this.checkIfNameMatchesContact(item?.fname, item?.lname)
          };
        });
      }
    });
  
    // const getCustomerSubs = this.contactService.getCustomer(this.serchArr).subscribe(result => {  
    //   if (result) {
    //     this.currentPage = result.currentPage;
    //     this.merContactListArr = result.data.map((item: any) => {
    //       return {
    //         ...item,
    //         name:item?.firstname,
    //         checked: this.checkIfNameMatchesContact(item?.firstname, item?.lastname)
    //       };
    //     });
    //   }
    // });
  }
  
  checkIfNameMatchesContact(firstName: string, lastName: string): boolean {
    
    return this.savedFilter?.contact?.some(contact => 
      contact?.first_name === firstName && contact?.last_name === lastName
    );
  }

  async getQuoteStages(){
     this.QuoteTemplateServices.get_Quote_Stages().subscribe({
      next:(resp)=>{
        if(resp){
          this.actionArr=resp?.data.sort((a,b)=>a.order.localeCompare(b.order))         
        }
      },
      error:(err)=>{
        console.log("got error : ",err);
      }
    })
  }

  wantDeletedQuotes(){
    if(this.deletedQuotes){
      this.savedFilter['deletedQuotes']='Deleted Quotes';
    }else this.savedFilter['deletedQuotes']=null;
  }

  quoteInvoice(){
    if(this.invoices){
      this.savedFilter['invoices']='quoteInvoice';
    }else this.savedFilter['invoices']=null;
  }

  isLoading: boolean = false;

onScroll(event: any) {
  const element = event.target;

  // Check if the scroll has reached the bottom
  if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    // Ensure it's not currently loading to prevent multiple API calls
    if (!this.isLoading) {
      this.loadMoreContacts(); // Call the API to load more contacts
    }
  }
}

loadMoreContacts() {
  this.isLoading = true; // Set loading flag to true
  this.serchArr = {
    pagination: true,
    limit: this.page?.pageSize,
    page: this.currentPage + 1 || 1,
    whereFilterData: this.where,
    search: this.globalSearchText ? this.globalSearchText : '',
    sortField: this.sortField,
    sortOrder: this.sortOrder
  };

  const merchantUserListSubs = this.QuoteTemplateServices.merchantUserList({list: 1}).subscribe(result => {
    if (result) {
      this.currentPage = result.currentPage;

      result.data = result.data.map((item: any) => {
        return {
          ...item,
          name: item?.fname,
          checked: this.checkIfNameMatchesContact(item?.fname, item?.lname)
        };
      });

      this.merContactListArr = [...this.merContactListArr, ...result.data];

      // Reset the loading flag after the API call is finished
      this.isLoading = false;
    }
  }, error => {
    // Reset the loading flag in case of error
    this.isLoading = false;
  });


}


}
