import { Component, OnInit, ViewChild, HostBinding, EventEmitter, Output, OnDestroy, Inject } from '@angular/core';
import { ItemsService } from '../services/items.service';
import { CategoryAddModel } from '../model/category-add-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})

export class AddCategoryComponent implements OnInit, OnDestroy {
    @HostBinding('class') componentCssClass: any;
    @ViewChild('closebutton') closebutton;

    @Output() getCategoryList: EventEmitter<any> = new EventEmitter<any>();
    
    categoryAddModel = new CategoryAddModel();   
    throwError:boolean=false     
// Private
    private _unsubscribeAll: Subject<any> = new Subject();
    constructor(
      private spinnerService: NgxSpinnerService,
      private itemsService: ItemsService,      
      public dialogRef: MatDialogRef<AddCategoryComponent>,
      @Inject(MAT_DIALOG_DATA) public inputData: any

  ) {     
  }
  ngOnInit(): void {  
    this.categoryAddModel.name = this.inputData.name; // Set the value here

  }
  closeDialog(){
    this.dialogRef.close(false)
  }

  addCateogry(cateogryForm) {    
    if(cateogryForm.form.status == "INVALID"){
      this.throwError=true
      return
    }
      if (cateogryForm.form.valid) {
        this.itemsService.categoryAdd(this.categoryAddModel).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          res => {
            if (res.message === 'success' || res.status === 1) {   
             this.closebutton.nativeElement.click();
             this.dialogRef.close(true);
             this.getCategoryList.emit();
              this.spinnerService.hide();
            } else {
              this.spinnerService.hide();
            }
        });
      }
	}


  updateCateogry(cateogryForm) {    
    if(cateogryForm.form.status == "INVALID"){
      this.throwError=true
      return
    }
    this.inputData.name=cateogryForm.form.value.name
      if (cateogryForm.form.valid) {
        this.itemsService.categoryUpdate(this.inputData).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          res => {
            if (res.message === 'success' || res.status === 1) {   
             this.closebutton.nativeElement.click();
             this.dialogRef.close(res);
             this.getCategoryList.emit();
              this.spinnerService.hide();
            } else {
              this.spinnerService.hide();
            }
        });
      }
	}

  updateAddress(cateogryForm) {    
    if(cateogryForm.form.status == "INVALID"){
      this.throwError=true
      return
    }
    const body={
      name:cateogryForm.form.value.name,
      id:this.inputData.id,
      title:cateogryForm.form.value.name,
      label:this.inputData?.label,
      old_name:this.inputData?.name,
      module_name:this.inputData?.module_name,
    }
      if (cateogryForm.form.valid) {
        this.itemsService.updateAddress(body).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          res => {
            if (res.message === 'success' || res.status === 1) {   
             this.closebutton.nativeElement.click();
             this.dialogRef.close(res);
            //  this.getCategoryList.emit();
              this.spinnerService.hide();
            } else {
              this.spinnerService.hide();
            }
        });
      }
	}
   
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
