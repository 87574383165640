// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  // API_URL:"http://localhost:3301",
  API_URL: "https://dev-backend.osmosapp.com",
  ITEM_MODULE_URL:"https://dev.osmosapp.com/items_module",
  USER_MANAGEMENT_URL:"https://dev.osmosapp.com/user_management",
  QUOTE_MANAGEMENT_URL: 'https://dev.osmosapp.com/quotes_management',
  ORG_MODULE_URL:"https://dev.osmosapp.com/organization_management",
  MAILBOX_MANAGEMENT_URL:"https://dev.osmosapp.com/mailbox_management",
  AGENDA_MANAGEMENT_URL:"https://dev.osmosapp.com/agenda_management",
  WEBSITE_URL: "https://dev-frontend.osmosapp.com",
  LEAD_MANAGEMENT_URL: "https://dev.osmosapp.com/lead_management",
  STRIPE_PUBLIC_KEY: "pk_test_51IxYZoBuv0ATMfMv66tUdi3DknArhNt0xnEhav5qk2uk2zrwZPW7UnhGIDEewVFZabXTbv950WqBsQxEC5zzDNtk00LBrLjMz6",
  MAILIFIER_KEY: "d14165a2-1f7c-4bde-b410-09e8681acbf2",
  // IMAGE_BACKET_URL: 'http://localhost:3301/user/getImage?imagename=',
  IP_DATA_KEY: "7310519aa7afd9ee1f093ae321e4af48e31c102c6945051e7cd71a24",
  // IMAGE_BACKET_URL: 'http://localhost:3301/user/getImage?imagename=',
  IMAGE_BACKET_URL: 'https://osmos-v2-images.s3.us-west-2.amazonaws.com/osmos-v2-images/',
  UPLOAD_NOTE_ATTACHMENT : 'https://osmos-v2-images.s3.us-west-2.amazonaws.com/upload_note_attachment/',
  ENCRYPTPASSWORD: '7r27srayk983fc05c',
  AUTOMATION_MANAGEMENT_URL:"https://dev.osmosapp.com/automations_management",
  recaptcha: {
    siteKey: '6LfKNi0cAAAAACeYwFRY9_d_qjGhpiwYUo5gNW5-', // Site key for production (live domain)
    secretKey:'6LerUAIpAAAAADTaJkgk1YpLExoww2mu9h3XgBk2'
  }
};