<div class="filter-container" [ngClass]="{'filterHeight': dataSource.length <= 5}">
    <div class="search-filter"><input type="text" placeholder="{{'LEADS.Search' | translate}}"></div>
    <div class="saved-filter" *ngIf="savedFilter">

        <span *ngIf="savedFilter?.deal_type?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.deal_type; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_type')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.actions?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.actions; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'actions')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.organization?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.organization; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'organization')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.contact?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.contact; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)">
                    {{ getTranslatedText("QUOTE_TEMPLATE." + sfilter?.first_name + " " + sfilter?.last_name) || (sfilter?.first_name + " " + sfilter?.last_name) }}
                  </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'contact')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.status?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " *ngFor="let sfilter of savedFilter?.status; let i = index;"
                [selectable]="selectable" [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'status')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter?.deal_stage?.length" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input "
                *ngFor="let sfilter of savedFilter?.deal_stage; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'deal_stage')"> close
                </mat-icon>
            </mat-chip>
        </span>
        <span *ngIf="savedFilter?.customFields" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input "
                *ngFor="let sfilter of savedFilter?.customFields; let i = index;" [selectable]="selectable"
                [removable]="removable">
                <a href="javascript:void(0)"> {{getTranslatedText ("QUOTE_TEMPLATE." + sfilter?.name ) || sfilter?.name}} </a>
                <mat-icon matChipRemove *ngIf="removable" (click)="removeFilter(sfilter?.id, 'customFields')"> close
                </mat-icon>
            </mat-chip>
        </span>

        <span *ngIf="savedFilter" class="d-flex flex-wrap">
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.singale_value"
                [removable]="removable">
                <a href="javascript:void(0)">{{getTranslatedText ("QUOTE_TEMPLATE." + savedFilter?.singale_value ) || savedFilter?.singale_value}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_value, 'singale_value')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable"
                *ngIf="savedFilter?.slider_value && savedFilter?.slider_value !='0-0'" [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.slider_value}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.slider_value, 'slider_value')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.singale_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.singale_date}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.singale_date, 'singale_date')"> close </mat-icon>
            </mat-chip>
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.from_to_date"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.from_to_date}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.from_to_date, 'from_to_date')"> close </mat-icon>
            </mat-chip>
            
            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.deletedQuotes"
                [removable]="removable">
                <a href="javascript:void(0)">{{savedFilter?.deletedQuotes}}</a>
                <mat-icon matChipRemove *ngIf="removable"
                    (click)="removeFilter(savedFilter?.deletedQuotes,'deletedQuotes')"> close </mat-icon>
            </mat-chip>

            <mat-chip class="m-1 filter-input tag-input " [selectable]="selectable" *ngIf="savedFilter?.invoices"
            [removable]="removable">
            <a href="javascript:void(0)">{{savedFilter?.invoices}}</a>
            <mat-icon matChipRemove *ngIf="removable"
                (click)="removeFilter(savedFilter?.invoices,'invoices')"> close </mat-icon>
        </mat-chip>
        </span>

    </div>
    <div class="acordion-filed">
        <mat-accordion>
            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Actions" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder='{{"QUOTE_TEMPLATE.Search Actions..." | translate}}' [(ngModel)]="actionSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of activityArr | search : actionSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'actions')">{{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Quote Status" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search Status ..." [(ngModel)]="statusSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of actionArr | search : statusSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'status')">  {{getTranslatedText ("QUOTE_TEMPLATE." + item?.name ) || item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"QUOTE_TEMPLATE.Organization" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search organization ..." [(ngModel)]="organizationSearchTags">
                </div>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <p *ngFor="let item of allOrgArr | search : organizationSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'organization')">{{item?.name}}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Client Name" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-search">
                    <input class="acordion-search-input" placeholder="Search client name ..." [(ngModel)]="contactSearchTags">
                </div>
                <div class="acordion-input" >
                    <span class="example-list-section" (scroll)="onScroll($event)">
                        <span>
                            <p *ngFor="let item of merContactListArr | search : contactSearchTags">
                                <mat-checkbox [(ngModel)]="item.checked"
                                    (change)="getAccorValue($event, item, 'contact')">{{ item?.name || '' }} {{ item?.lastname || '' }}
                                </mat-checkbox>
                            </p>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>
            
            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Deleted Quotes" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <mat-checkbox [(ngModel)]="deletedQuotes" (change)="wantDeletedQuotes()"
                                >Deleted Quotes
                            </mat-checkbox>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="py-2">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{"Invoice" |translate}}</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="acordion-input">
                    <span class="example-list-section">
                        <span>
                            <mat-checkbox [(ngModel)]="invoices" (change)="quoteInvoice()"
                                >Invoices
                            </mat-checkbox>
                        </span>
                    </span>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>
    <div class="apply-filter-field mt-4">
        <div class="clear-filter-btn">
            <button type="button" (click)="resetFilter()">
                <app-custom-icon icon="round_close"></app-custom-icon> {{"LEADS.Clear_Filter"|translate}}
            </button>
        </div>
        <div class="apply-filter-btn">
            <button type="button" (click)="saveFilter()">
                <app-custom-icon icon="round_correct"></app-custom-icon> {{"LEADS.Apply_Filter"|translate}}
            </button>
        </div>
    </div>
</div>