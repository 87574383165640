<div class="position-relative mt-4 phone-input" (mouseenter)="openBulkAction = false">
  <app-custom-icon icon="telephone" *ngIf="isIconShow" class="me-2"></app-custom-icon>
  <div
    class="d-inline-block fw-500 text-1 align-top"
    [ngClass]="{ isIconShow: 'ms-2' }"
  >
    {{ "BUSINESS_PROFILE.Phone
        Number" | translate }}
</div><span *ngIf="phoneRequired" class="color-6">*</span>
  <div class="mt-2">
    <div class="d-flex" class="align-items-center phone_div" fxLayoutGap="10" *ngFor="let contact of businessContact; let i = index"
    [ngClass]="{ 'phone_div_resp': moduleName == 'Contact','phone_div_resp_org':moduleName == 'Organization Contact'}">
      <div class="countryId" style="width: 20%;"
      [ngClass]="{ 'countryId_resp': moduleName == 'Contact','countryId_resp_org':moduleName == 'Organization Contact', '!w-[25%]':!notFromEQuote && !fields?.type?.length , '!w-[17%]':fields?.type?.length}">
        <app-select-control
          [selectedId]="contact.countryId"
          [itemList]="countryListArr"
          [index]="i"
          [type]="'countryId'"
          [moduleName] = "moduleName"
          [notFromEQuoteCountry]="notFromEQuote"
          (changeValue)="changephonenumberselect($event)"
        >
        </app-select-control>
      </div>
      <div class="mobile_number" style="width: 20%;"
      [ngClass]="{ 'mobile_number_resp': moduleName == 'Contact','mobile_number_org':moduleName == 'Organization Contact', '!w-[24.5%]':!notFromEQuote && !fields?.type?.length, '!w-[16%]':fields?.type?.length}">
        <mat-form-field appearance="outline">
          <input
            matInput
            type="text"
            placeholder="{{'GENRIC_PLACEHOLDER.Number' | translate}}"
            appNumbersOnly
            name="mobile_number"
            [ngModel]="contact.mobile_number"
            (ngModelChange)="changephonenumberinput($event, i, 'mobile_number')"
          />
        </mat-form-field>
      </div>
      <form *ngIf="fields?.type?.length || formContactListArr?.length" [formGroup]="addContactForm" class="mobile_form" style="margin-right: 10px !important;"
      [ngClass]="{ 'mobile_form_resp': moduleName == 'Contact','mobile_form_resp_org':moduleName == 'Organization Contact', '!w-[16%]':fields?.type?.length }">

      <div *ngIf="flag == true && notFromEQuote"  class="mobile_name">
        <mat-form-field id="country" appearance="outline" floatLabel="always">
          <mat-select id="num_name" class="text-1" placeholder="Select And Add Name"
            (selectionChange)="addFormOfContact($event)"
              [(ngModel)]="contact.name"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="changephonenumberinput($event, i, 'name')"
              >
              <mat-option class="text-1" value="">
                  {{"CONTACT_PROFILE.Select_contact_name" | translate}}
              </mat-option>
              <mat-option id="option_num" class="text-1 d-flex align-items-center"
                  *ngFor="let formContactList of formContactListArr; let i = index"
                  [value]="formContactList.name">
                 <!-- <mat-icon class="vertical-align-baseline">
                      <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                          src="assets/img/home/delete.svg"
                          (click)="removeItem(formContactList.id,'deleteformContact')">
                  </mat-icon> <i class="fa fa-edit edit-icon mr-2" style="color: #03C0FC;" (click)="updateSubcategories(formContactList,formContactListArr)"></i>-->
                  <div class="dropdown navbar text-right p-0 px-2" style="background: transparent">
                            <button class="menu-btn2 fs-2 text-right" id="sideMenuDropDown" aria-expanded="false"
                                (click)="openBulkActionPopup($event,i)">
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="16.6802" cy="11.9829" r="1.46341" fill="#03C0FC" />
                                                        <circle cx="11.9986" cy="11.9829" r="1.46341" fill="#03C0FC" />
                                                        <circle cx="7.31693" cy="11.9829" r="1.46341" fill="#03C0FC" />
                                                        <circle cx="12" cy="12" r="11.25" stroke="#03C0FC" stroke-width="1.5" />
                                                    </svg>
                            </button>
                            <div class="shadow-sm dropdown-menu bg-0 dropdown-1 flex-column gap-3 show"
                                *ngIf="openBulkAction && currentIndex === i" aria-labelledby="sideMenuDropDown" style="top: 85%;">
                                <div class="px-2 flex flex-col gap-1">
                                  <div class="flex items-center gap-3" (click)="removeItem(formContactList.id,'deleteformContact')">
                                    <mat-icon class="vertical-align-baseline m-0">
                                    <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                                        src="assets/img/home/delete.svg"
                                        >
                                   </mat-icon>
                                   {{"AUTOMATION.delete" | translate}}
                                  </div>
                                 
                                  <div class="flex items-center gap-3 px-1" (click)="updateSubcategories(formContactList,formContactListArr)">
                                    <i class="fa fa-edit edit-icon" style="color: #03C0FC;" ></i>
                                   {{"AGENDA.edit" | translate}}
                                  </div>
                                    
                                </div>
                              
                            </div>
                        </div>
                        <div (click)="openBulkAction = false">{{getTranslatedText ("CONTACT_PROFILE." + formContactList.name) || formContactList.name}}</div>   
              </mat-option>
              <mat-option class="text-1" value="addFormOfContact" (click)="addFormOfContact('addFormOfContact')">
                  <mat-icon class="vertical-align-baseline">
                      <svg width="30" height="35" viewBox="0 0 55 55" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle cx="27.5" cy="27.5" r="27.5" fill="#03C0FC" />
                          <path
                              d="M38.2143 29.2857H29.2857V38.2143C29.2857 39.1964 28.4821 40 27.5 40C26.5179 40 25.7143 39.1964 25.7143 38.2143V29.2857H16.7857C15.8036 29.2857 15 28.4821 15 27.5C15 26.5179 15.8036 25.7143 16.7857 25.7143H25.7143V16.7857C25.7143 15.8036 26.5179 15 27.5 15C28.4821 15 29.2857 15.8036 29.2857 16.7857V25.7143H38.2143C39.1964 25.7143 40 26.5179 40 27.5C40 28.4821 39.1964 29.2857 38.2143 29.2857Z"
                              fill="white" />
                      </svg>
                  </mat-icon>
                  {{"CONTACT_PROFILE.Add_New" | translate}}
              </mat-option>
          </mat-select>
      </mat-form-field>
</div>


<div *ngIf="flag == true && !notFromEQuote && fields?.type?.length"  class="mobile_name" >
        <mat-form-field id="country" appearance="outline" floatLabel="always">
          <mat-select id="num_name" class="text-1" placeholder="Select And Add Name"
            (selectionChange)="addFormOfContact($event)"
              [(ngModel)]="contact.name"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="changephonenumberinput($event, i, 'name')"
              >
              <mat-option class="text-1" value="">
                  {{"CONTACT_PROFILE.Select_contact_name" | translate}}
              </mat-option>
              <mat-option id="option_num" class="text-1 d-flex align-items-center"
                  *ngFor="let formContactList of fields?.type; let i = index"
                  [value]="formContactList">
                 <!-- <mat-icon class="vertical-align-baseline">
                      <img class="vertical-align-baseline" style="width: 25px;height: 20;" role="button"
                          src="assets/img/home/delete.svg"
                          (click)="removeItem(formContactList,'deleteformContact')">
                  </mat-icon> <i class="fa fa-edit edit-icon mr-2" style="color: #03C0FC;" (click)="updateSubcategories(formContactList,formContactListArr)"></i>-->
                 
                        <div (click)="openBulkAction = false">{{formContactList}}</div>   
              </mat-option>
              
          </mat-select>
      </mat-form-field>
</div>

      </form>

    


      <!-- <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          name="name"
          placeholder="{{'GENRIC_PLACEHOLDER.PHONE_NAME' | translate}}"
          value="{{ contact.name }}"
          (input)="changephonenumberinput($event, i, 'name')"
        />
      </mat-form-field> -->


      <div class="min-h-100 remove-arrow text-3" fxFlex="5">
        <button
          class="btn"
          type="button"
          (click)="removeItem(i, 'phonenumber')"
        >
          <p class="d-inline-block remove-contact-number"><i class="fas fa-times"></i></p>
        </button>
      </div>
    </div>

    <button
      class=" add-btn add-btn-plain-link mt-2 add-phn-num"
      (click)="addPhoneRow()"
      type="button"
    >
      <i class="fas fa-plus-circle"></i>
      {{ "BUSINESS_PROFILE.Add Phone Number" | translate }}
    </button>

    <div class="col-lg-12">
      <button hidden type="button" class="btn bg-1 btn-circle fs-3 "
          #addFormOfContactButton1 data-bs-target="#add_form_of_contact1"
          data-bs-toggle="modal" aria-expanded="false">+</button>
      <div class="modal fade" id="add_form_of_contact1" data-bs-backdrop="false"
          tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered"
              style="max-width: 600px;">
              <div class="modal-content cnt-dashboard border-r2 border shadow">
                  <div class="modal-header pt-5">
                      <h2 class="text-1 fs-4 text-center">
                          {{"Phone Name" | translate}}</h2>
                      <i type="button" data-bs-dismiss="modal" #closeformOfConForm1
                          aria-label="Close"
                          class="color-4 fs-2 far fa-times-circle position-absolute end-0 top-0 m-3"></i>
                  </div>
                  <form [formGroup]="formOfContactForm1"
                      (ngSubmit)="addFormOfContactList1()">
                      <div class="modal-body bg-gris-1 px-5">
                          <label class=" mb-2">
                              {{"CONTACT_PROFILE.Name" | translate}}
                              <span class="color-6">*</span>
                          </label>
                          <!-- <mat-form-field 
                              appearance="outline" 
                              floatLabel="always"> -->
                          <input type="text" class="form-control"
                              placeholder="{{'GENRIC_PLACEHOLDER.Enter_phone_name' | translate}}"
                              formControlName="name"
                              
                              [ngClass]="{ 'is-invalid': formOfContactFormSubmit && formOfContact.name.errors , 'is-valid': formOfContactFormSubmit && formOfContact.name.valid }">
                          <!-- <mat-error *ngIf="formOfContactFormSubmit && formOfContact.name.errors && formOfContact.name.errors.required">
                                  {{"CONTACT_PROFILE.ERRONAMEREQUIRED" | translate}}
                              </mat-error> -->
                          <!-- </mat-form-field>                                                              -->
                          <!-- <div class="mt-3" v>
                              <label class="text-3 mb-2">{{"CONTACT_PROFILE.COLOR"
                                  | translate}}
                                  <span class="color-6">*</span>
                              </label>
                              <div class="position-relative">
                                  <mat-form-field appearance="outline"
                                      floatLabel="always">
                                      <input matInput type="text"
                                          class="source-color-text"
                                          formControlName="color"
                                          [value]="color"
                                          (input)="changeColor1($event)">
                                      <input matInput
                                          class="p-0 position-absolute end-0 top-0 m-2 text-1"
                                          (input)="changeColor($event)"
                                          [value]="color"
                                          formControlName="color"
                                          style="width: 15px; height: 15px;margin-top: 3px!important"
                                          type="color" id="colorPicker"  />
                                      <mat-error>
                                          {{"CONTACT_PROFILE.Please_enter_color_code"
                                          | translate}}
                                      </mat-error>
                                      <mat-hint class="mt-3">{{"CONTACT_PROFILE.Color_hint" | translate}}</mat-hint>
                                  </mat-form-field>
                                  
                              </div>
                          </div> -->
                      </div>

                      <div class="modal-footer j-c-center mt-4 pb-4">
                          <button type="button" class="btn btn-skyblue-s-1 standard-btn" id=""
                              data-bs-toggle="modal" data-bs-dismiss="modal"
                              data-bs-target="#modal-new"
                              #closebutton>{{"SYSTEM_USER.Cancel" | translate}}
                          </button>
                          <button type="submit"
                              class="btn btn-p-2 standard-btn mx-2">Add</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
  </div>
</div>

