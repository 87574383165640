import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { emptyAddress } from 'src/app/shared/enum/empty-address';
import { AddressCompComponent } from 'src/app/pages/lead-form/address-comp/address-comp.component';
import { LeadFormService } from 'src/app/pages/lead-form/services/lead.form.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BusinessProfileService } from 'src/app/pages/business-profile/services/business-profile.service';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { QuoteTemplateService } from '../services/quote-template.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'embedform2',
  templateUrl: './embedform.component.html',
  styleUrls: ['./embedform.component.scss']
})
export class quoteEmbedformComponent implements OnInit {
  @ViewChild('previewContent') previewContent: ElementRef;
  @Input() embedFromData: any;
  embedFromData1: any;
  currencyArr: any;
  myLeadForm: FormGroup;
  checkedValues: string[] = [];
  selectedFiles: File[] = [];
  tableData: string[][] = [];  rowsArray: any[];
  crSymbol:any;
  selectedRowCount: number = 3;
  selectedColumnCount: number=3;
  headerData: string[] = [];
  isDragOver = false;
  subscriptions: Subscription[] = [];
  countryListArr: any[] = [];
  phonenumber:any[] = [{ 'countryId': '', 'mobile_number': '', 'name': '', 'order': 0 }];
  staticLogo: string = `${environment.UPLOAD_NOTE_ATTACHMENT}1699445669424_placeholder_logo.png`;
  protected onDestroy = new Subject<void>();
  customerAddressArr: any = [new emptyAddress()];
  labelName1='address';
  moduleName='Lead Form Address';
  selectedCurrency: any
  @ViewChild(AddressCompComponent, { static: false }) AddressCompComponent: AddressCompComponent;
  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private businessProfileService: BusinessProfileService,
    public leadService: LeadService,
    private QuoteTemplateService: QuoteTemplateService,
    private elementRef: ElementRef, private renderer: Renderer2,
    public router: Router,

  ) {
    this.myLeadForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.getTemplate();
    this.leadService.currencySymbal$.subscribe((res: any) => { this.crSymbol = res; });
    // this.leadForm_service?.countryList().subscribe((val: any) => {
    //   this.countryListArr = val?.data;
    // })
    const id = this.route.snapshot.params['id'];
if(id){
  const get_Lead_Form_Subs = this.QuoteTemplateService.get_Lead_Form(id).subscribe((res: any) => {
    if (res?.data) {
      this.embedFromData1=res?.data?.html
      this.setReadonlyForInputAndTextarea();

    }
    this.subscriptions.push(get_Lead_Form_Subs);

  }, (error) => {
  });

}
    // Call your data service to fetch data based on the 'id'
    // this.getCurrency()

    
  }

  get getColor1() { return this.embedFromData.data?.color; }
  get getFmly1() { return this.embedFromData?.data.font_family; }
  get getFsize1() { return this.embedFromData?.data.font_size; }
  get getLogo1() { return this.embedFromData?.style?.logo; }
  get getCheckbox1() {const value = this.embedFromData?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }

  get getData() { return this.embedFromData?.data; }
  get getColor() { return this.embedFromData?.data.color; }
  get getFmly() { return this.embedFromData?.style?.font_family; }
  get getFsize() { return this.embedFromData?.data?.font_size; }
  get getLogo() { return this.embedFromData?.style?.logo; }
  get getCheckbox() {const value = this.embedFromData?.data?.find((el: any) => el?.form_name === 'Checkbox');
    return value?.custom_value ? value?.custom_value?.value : value?.value;
  }
  get getAttechValue() { return this.embedFromData?.data?.find((el: any) => el?.form_name === 'Attachment')?.value }
  get acceptFile(): string {
    const acceptedTypes = this.getAttechValue?.filter((fileType: any) => fileType?.value)?.map((fileType: any) => `.${fileType?.name?.toLowerCase()}`)?.join(',');
    return acceptedTypes;
  }
  onSubmit() {
    const address = this.AddressCompComponent?.addressItems?.lineItems[0];
    this.myLeadForm?.controls['Address']?.setValue(address);
    if (this.myLeadForm.invalid) {
      console.log('myLeadForm.invalid', this.myLeadForm.value);
      return
    }
    console.log('myLeadForm.valid', this.myLeadForm.value);
  }

  getSafeHtml(): SafeHtml {
      const decodedHtml = decodeURIComponent(this.embedFromData1);
      const trustedHtml = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
      return trustedHtml;
  }

  

  createForm(embed: any): FormGroup {
    if (!this.myLeadForm) {
      this.myLeadForm = this.fb.group({});
    }
    if (embed?.type !== 'content') {
      if (embed?.form_name !== 'Submit') {
        if (embed?.required) {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null, Validators.required));
        } else {
          this.myLeadForm.addControl(embed?.form_name, this.fb.control(null));
        }
      }
    }
    return this.myLeadForm;
  }

  onCheckboxChange(event: any, data: any) {
    const valueAt = this.getCheckbox?.findIndex((el: any) => el?.name == data?.name);
    const checked = event?.target?.checked;
    if (valueAt !== -1) {
      this.getCheckbox[valueAt].selected = checked;
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  ngAfterViewInit(): void {
    this.setReadonlyForInputAndTextarea();
    if (this.myLeadForm?.controls['Checkbox'] !== undefined) {
      this.myLeadForm?.controls['Checkbox']?.setValue(this.getCheckbox);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
    if (this.isInDropZone(event)) {
      event.dataTransfer.dropEffect = 'copy';
    } else {
      event.dataTransfer.dropEffect = 'none';
    }
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.handleFiles(event.dataTransfer.files);
  }

  private isInDropZone(event: DragEvent): boolean {
    const targetElement = event.target as HTMLElement;
    return targetElement.matches('#attachment_icon');
  }

  onFileSelected(event: any) {
    this.handleFiles(event.target.files);
  }

  handleFiles(files: FileList | File[]) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (this.isValidFile(file)) {
        this.selectedFiles.push(file);
        this.myLeadForm?.controls['Attachment']?.setValue(this.selectedFiles);
      }
    }
  }
  initializeHeaderData() {
    for (let j = 0; j < this.selectedColumnCount; j++) {
      this.headerData.push(`Column ${j + 1}`);
    }}

  initializeTableData() {
    for (let i = 0; i < this.selectedRowCount; i++) {
      const row: string[] = [];
      for (let j = 0; j < this.selectedColumnCount; j++) {
        row.push('');
      }
      this.tableData.push(row);
    }}


  async getCurrency() {
    const currencySubs = await this.businessProfileService.currency().subscribe((val: any) => {
      // Assuming that val.data is an array of currencies
      this.currencyArr = val.data;
      const matchingCurrency = val.data.find(
        currency =>
          currency.currency_symbol === this.crSymbol 
      );
      this.selectedCurrency = { currency_code: matchingCurrency.currency_code, currency_symbol:matchingCurrency.currency_symbol };    
      // Check if currencyArr is empty or not
      if (this.currencyArr.length > 0) {
        // If currencyArr is not empty, set the default currency as the first item in the array
        this.selectedCurrency = this.currencyArr[1];
      } else {
        // Handle the case where no currencies were fetched
        // You can set a default currency or handle this scenario as per your requirements
      }
  
    });

    // const matchingCurrency = this.currencyArr.find(
    //   currency =>
    //     currency.currency_symbol === this.crSymbol
    // );  tu
    // this.selectedCurrency = matchingCurrency;

  }

  isValidFile(file: File): boolean {
    let accepted: string[] = [];
    const fileTypeMap = new Map<string, string[]>([['DOX', ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']], ['PDF', ['application/pdf']], ['PNG', ['image/png']], ['JPG', ['image/jpeg']]]);
    this.getAttechValue.forEach(fileType => { if (fileType?.value && fileTypeMap?.has(fileType?.name)) { accepted.push(...fileTypeMap.get(fileType?.name)!); } });
    const maxSize = 10 * 1024 * 1024;
    if (!accepted.includes(file.type)) {
      this.errorSnackBar('Invalid file type.', 'OK');
      return false;
    }
    if (file.size > maxSize) {
      this.errorSnackBar('File size exceeds the limit.', 'OK');
      return false;
    }
    return true;
  }

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  errorSnackBar(msg, tag) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'right';
    config.duration = 3000;
    config.panelClass = ['notif-error'];
    this.snackbar.open(msg, tag, config);
  }

  ngOnDestroy(): void {
    this.onDestroy.next(null);
    this.onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTemplate() {
    if (this.embedFromData1) {
      this.embedFromData1 = null;
    }
    this.route.data.subscribe((data) => {
      if (data?.viewTemplateData) {
        const embedFrom = data.viewTemplateData?.data?.quotes_templates_fields;
        if (embedFrom) {
          this.embedFromData1 = {
            data: embedFrom?.map((el: any) => el?.field_styles),
            style: {
              color: embedFrom[0]?.field_styles?.colors,
              font_family: embedFrom[0]?.field_styles?.fontFamily,
              font_size: {
                head: embedFrom[0]?.field_styles?.headingSize,
                body: embedFrom[0]?.field_styles?.bodySize,
                helptxt: embedFrom[0]?.field_styles?.helpTextSize
              },
              logo: embedFrom[0]?.field_styles?.imageURL
            }
          }
        }
      }
    });
  }
  makeInputFieldsReadonly() {
    if (this.embedFromData1) {
      const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
      inputElements.forEach((input: HTMLInputElement) => {
        this.renderer.setAttribute(input, 'readonly', 'true');
      });
    }
  }

  private setReadonlyForInputAndTextarea() {
    if (this.embedFromData1 && this.previewContent) {
      const inputElements = this.previewContent.nativeElement.querySelectorAll('input, textarea');
      inputElements.forEach((element: HTMLInputElement | HTMLTextAreaElement) => {
        this.renderer.setAttribute(element, 'readonly', 'true');
      });
    }
  }

  closeDialog() {
    this.router.navigate([`dash/quote-template`]);
  }

  conorglead(el: string): boolean {  return el === 'First Name' || el === 'Last Name' || el === 'Email' || el === 'Address' || el === 'Organization' || el === 'Business N.' || el === 'Company' || el === 'Industry'; }

}

