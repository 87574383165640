import { Color } from 'ng2-charts';

import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, NgZone, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';
import { BusinessProfileService } from 'src/app/pages/business-profile/services/business-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationViewService } from 'src/app/pages/contact-profile/view-organization/services/view-organization.service';
import { ProfileService } from 'src/app/pages/contact-profile/services/contact-profile.service';
import { ItemsService } from 'src/app/pages/items/services/items.service';
import { Subject, Subscription } from 'rxjs';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { CustomDateFormatPipe } from 'src/app/shared/pipes/custom-date-format/custom-date-format.datepipe';
import { MatDatepicker } from '@angular/material/datepicker';
import { AnimationOptions } from 'ngx-lottie';
import SignaturePad from 'signature_pad';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { EditContactService } from 'src/app/pages/contact-profile/edit-contact/services/edit-contact.service';
import { DataService } from 'src/app/quote/quote-listing/contact-quote.service';
import { MatConfirmDeleteComponent } from 'src/app/Dialogs/mat-confirm-delete/mat-confirm-delete.component';
import { DecimalPipe } from '@angular/common';
import { ItemAddModel } from 'src/app/pages/items/model/item-add-model';
import { AddEditItemComponent } from 'src/app/pages/items/add-edit-item/add-edit-item.component';
import { taskReducer } from 'src/app/pages/tasks/reducers/task.reducer';
import { MatSelect } from '@angular/material/select';
import { SignaturePadComponentCreate } from 'src/app/quote/signature-pad/signature-pad.component';
import { MatTabGroup } from '@angular/material/tabs';


export interface PeriodicElement {
  id:any;
  item: any;
  quantity: string;
  price: any;
  discount: string;
  discPrice: any;
  totalPrice: any;
  category: string;
  subCategory: string;
  itemsComing: any;
  weight: any;
  delete:any;
  picture:any;
  stockStatus:any;
  totalCost: any;
  salesCommission: any;
  profitMargin: any;
  isMerged?: boolean;
}

const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-new-tmp',
  templateUrl: './new-tmp.component.html',
  styleUrls: ['./new-tmp.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class NewTmpComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('signaturepad') signaturepad: any;
  isSignatureEmpty: boolean = true;
  selectedTabIndex: any = 0;
  paymentPage: boolean = false;
  debitCardForm: FormGroup;
  isSlideToggled: boolean = true;
  isItemDisable:boolean = true
  isSlideQuantity: boolean = false;
  modifyPrice:boolean = false;
  receivedData:any
  transferForm: FormGroup;
  profit: number = 0
  paymentMethod = 'card';
  options: AnimationOptions = {    
    path: "../../../assets/lottie/main_dp.json"
  };
  
   update_id
  private _unsubscribeAll: Subject<any> = new Subject();
  @ViewChild('formContent') formContent: ElementRef;
  @ViewChild('signaturePadCanvas') signaturePadCanvas: ElementRef;
  @ViewChild('quoteCurrency') quoteCurrency: ElementRef;
  @ViewChild('salesTaxSelect') salesTaxSelect: ElementRef;

  selectedDate: Date | string;
  sentDate:any;
  afterDiscount:any=0
  newDiscount:any;
  newPriceA:any;
  aftertax:any=0
  totalCost:any=0
  profitmargin:any=0
  showThankYouDialog:any;
  salesCommission:any=0
  itemCategoryId:any;
  newItemData:any;
  itemNewId:any;
  afterDiscount1:any=0
  aftertax1:any=0
  applyDiscount:any;
  totalCost1:any=0
  profitmargin1:any=0
  salesCommission1:any=0
  public profitMargn:any = 0
  ownername:any;
  selectedPriceA:any;
  rateValue:any;
  itemsListArr:any=[];
  viewByMe:any;
  contactView:any;
  contactSince:any;
  addedItemsData:any[]=[];
  savedSignatureDataUrl: string | null = null;
  tableData: string[][] = [];
  rowsArray: any[];
  sendMailDate:any;
  quoteListingData:any;
  htmlContent: string = '';
  actpageIndx: number = 0;
  headerData: string[] = [];
  currencyArr:any;
  userEmail:any;
  showCurrencySymbol:any;
  showCurrencySymbolExchange:any;
  pricesArrayNew:any
  dataSource=ELEMENT_DATA;
  selectedCurrency:any;
  quoteSentDate:any
  creationDate:boolean=false;
  headerData1: string[] = [
    'Item name',
    'Item code',
    'Item image',
    'Video URL',
    'Website URL',
    'Custom item fields',
    'Description',
    'Item comments',
    'Item Unit',
    'Quantity',
    'Item price',
    'Tax Exempt indicator',
    'Currenccy',
    'Discount',
    'Applicable taxes',
    'Quote ID',
    'Invoice ID',
    'Sales Order ID',
    'Price',
    'Total',
  ];
  selectedRowCount: number = 3;
  selectedColumnCount: number = 3;
  addressArr: any;
  staticLogo: string =
    'https://osmos-bucket-prod.s3.us-east-1.amazonaws.com/upload_note_attachment/1699445669424_placeholder_logo.png';
  contactId: any;
  widgetsPanel: boolean = false;
  userProfilePic: any;
  pricePanel: boolean = false;
  inforDropdown: boolean = false;
  addPrices: boolean = false;
  tableStyleClass = 'table-style-default'

  tagsList = [
    { item: 'Design' },
    { item: 'Website' },
    { item: 'UI/UX' },
    { item: 'Dashboard' },
    { item: 'Awesome' },
  ];
  currenciesList = [
    { item: 'USD' },
    { item: 'EUR' },
    { item: 'Pound' },
    { item: 'Pkr' },
  ];
  selectedCurrencyDeafult = this.currenciesList[0]?.item;
  // dataSource: any = [];
  columnsToDisplay = [
    'item',
    'quantity',
    'weight',
    'price',
    'discount',
    'discPrice',
    'totalPrice',
  ];
  columnsToDisplayWithExpand :any ;
  expandedElement: PeriodicElement | null;
  slectedPrice: any;
  selectedDiscount: any;
  fixCostUnit:any;
  sales_comm_type:any;
  sales_commission:any;
  itemPrices: any;
  newAddress = [];
  newAddressbui = [];
  dealDescription = [];
  newAddressorg = [];
  workSpaceData: any;
  newPhone = [];
  newPhoneorg = [];
  newPhonebui = [];
  selectedPrice: any = '';
  newEmail = [];
  globalSearchText = '';
  sortField: any = 'created_at';
  sortOrder: any = 'DESC';
  selectedStore: any;
  lengthValue: number = 10;
  widthValue: number = 5;
  orgList: any;
  totalValue: any;
  merchantContactData: any;
  merchantQuoteData: any;
  contactProfilePic: any;
  viewOrganizationArr: any;
  orgName: any;
  where: any = [];
  socialLinks: any;
  imageURL1: any;
  serchArr: any;
  staticLogo2: string;
  // update_id:any='';
  itemData: any;
displayedColumnsKeys: string[] ;

  customerLeadDetailsArr: any;
  itemDeletedata: any;
  formattedSelectedDate:any;
  dropListData = [
    {
      page: 'page00',
      items: [],
    },
    // Add more pages as needed
  ];
  dateValue:any
  showAllAddresses: boolean = false;
  @ViewChild('temp_ref') temp_ref: ElementRef;
  itemCount = 10;
  nextPageNumber: number = 1;
  pages: any[] = ['page00'];
  tables: any[][] = [];
  public imageUrl = environment.IMAGE_BACKET_URL;
  subscriptions: Subscription[] = [];
  crSymbol:any;
  addressesToShow: number = 2;
  showCanvas = false;
  formContactList:any;
  private signaturePad: SignaturePad;
  textContent: string = 'Show more';
  isExpanded: boolean = false;
  initialDescriptionLength: number = 30;
  merchantId: string;
  businessDataArr: any;
  showPhones: boolean = false;
  userName = new FormControl('', [Validators.required]);
  agreementChecked = new FormControl(false,[Validators.required]);
  chequeImage: string = '../../../assets/icons/cheque_black.png';
  otherImage: string = '../../../assets/icons/cheque.png';
  clearSignatureValue = null;
  // Add this method to track elements in ngFor
  trackByFn(index: number, item: any): any {
    return index;
  }

  subCategoryName = 'Motor Car';
  categoryName = 'Cars';
  itemAddModel = new ItemAddModel();
  @ViewChild(MatSelect) select: MatSelect;
  newItemPriceList:any={pricesA: '', pricesB: '', pricesC: '', pricesD: ''} ;
  itemPriceList = {pricesA:'1000.00', pricesB: '800.00',pricesC:'600.00',pricesD:'500.00'};
  newPrice = new FormControl('');
  allTaxesList = [{taxData:{name:'Hs',customValueId:1},taxe_value:'10'},{taxData:{name:'Va',customValueId:2},taxe_value:'5'}]
  addDiscountsArr = [];
  addItemPriceTaxArr=[];
  inputData = {cost:'500',profitMargin:'40.4',salesCommission:'70'};
  lenghtValue = 5;
  width_Value = 8;
  @ViewChild('signaturePad') signaturePads!: SignaturePadComponentCreate;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  constructor(
    public dialogRef: MatDialogRef<NewTmpComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dailogData: any,
    public router: Router,
    private sanitizer: DomSanitizer,
    public quoteTemplateService: QuoteTemplateService,
    public dialog: MatDialog,
    private dataService: DataService,
    private _router: Router,
    public QuoteTemplateServices: QuoteTemplateService,
    private businessProfileService: BusinessProfileService,
    private organizationservice: OrganizationViewService,
    private profileService: ProfileService,
    private itemsService: ItemsService,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private datePipe: CustomDateFormatPipe,
    public leadService: LeadService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
    private editContactService: EditContactService,
    private decimalPipe: DecimalPipe,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2, private el: ElementRef
  ) { 
    this.initForm();
    this.agreementChecked = new FormControl(false);
  }
  title: string;
  newtemFrom: FormGroup;
  addQuoteStageForm: FormGroup;
  newtemSubmit: boolean = false;
  leadTags = Array();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur: boolean = true;
  removable: boolean = true;
  selectable: boolean = true;
  updatetemp_id: any;
  submitType:string='';
  ngOnInit(): void {
    this.leadService.currencySymbal$.subscribe((res: any) => { this.crSymbol = res; });
    this.savedSignatureDataUrl = null
    this.addNewTemForm();
    this.title = this.dailogData?.title;
    if(this.dailogData.type =='quote'){
      this.editItemList(this.dailogData.id)
    }
    if (this.dailogData.type === "clone" || this.dailogData.type === "edit" || this.dailogData.type === "duplicate") {
      this.patchForm(this.dailogData);
    }
    this.createForm();
    this.transferForm = this.fb.group({
      transferType: ['', Validators.required],
      accountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      beneficiary: ['', Validators.required]
    });
    if(this.dailogData?.type == 'Quote preview' || this.dailogData?.type == 'view'){

      document.addEventListener("DOMContentLoaded", function() {
        const parentDiv = document.getElementById("parentId");
        if (parentDiv) {
            const elements = parentDiv.querySelectorAll("*");
            elements.forEach((element: HTMLElement) => {
                element.setAttribute("readonly", "true");
            });
        }
    });
   
    
    
    if(this.dailogData.field_styles){
      let field_list = this.dailogData.field_styles
      if (this.pages.indexOf(field_list?.page) === -1) {
        this.pages.push(field_list.page);
      }
      const mappedItems = field_list.map((y) => {
        return {
          page: y.page,
          items: y.items,
          field_order: y.field_order,
        };
        
      });
      
      // mappedItems.sort((a, b) => a.field_order - b.field_order);
      this.dropListData = mappedItems;
      
      this.dropListData.forEach((item) =>{
        
        item.items.forEach(async (item2)=>{
        
          
          if(item2.name =='Item Table'){

            if(item2.class_name){
              this.tableStyleClass = item2.class_name
            }
            
            this.tables=item2.table
            this.columnsToDisplayWithExpand =[...item2?.displayedColumns11]
            this.columnsToDisplayWithExpand = [...this.columnsToDisplayWithExpand];
  
          }
          
          
          // this.displayedColumnsKeys = this.columnsToDisplayWithExpand.map(column => column.name)
          // console.log(this.displayedColumnsKeys,'wwwwwwwwwwww');
         
    // this.columnsToDisplay = this.columnsToDisplay
        })
      })
      document.addEventListener("DOMContentLoaded", function() {
        const parentDiv = document.getElementById("parentId");
        if (parentDiv) {
            const elements = parentDiv.querySelectorAll("*");
            elements.forEach((element: HTMLElement) => {
                element.setAttribute("readonly", "true");
            });
        }
    });
      // this.sourceOfContact()
      // this.leadService.currencySymbal$.subscribe((res: any) => { this.crSymbol = res; console.log(res,'nnnnnjjjjj') });
    }
   
    }
    


    if(this.dailogData?.type=='Quote stage'){
      this.addQuoteStageForm.patchValue(this.dailogData?.data);
    }
  }

  initForm(){
    this.addQuoteStageForm=this.fb.group({
      name:['',Validators.required],
      color:['',Validators.required],
      order:[''],
    })
  }

  clearSignature(): void {
    this.signaturePads.clearSignature();
    this.isSignatureEmpty = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
   
    
    if (changes.clearValue && changes.clearValue.currentValue === 'clear') {
      console.log('rrrrrrrrrr');
      
     this.clearSignatureValue = 'null'
    } else {
      this.clearSignatureValue = 'clear'
    }
    const paginationButton = this.tabGroup._elementRef.nativeElement.querySelector('.mat-tab-header-pagination-after');
   
   // Attach a click event listener to the pagination button
   this.renderer.listen(paginationButton, 'click', () => {
     // Check if the window width is less than or equal to 425
     if (window.innerWidth <= 425) {
      
       this.selectedTabIndex+1
       // Add your logic to increase the tab here
     }
   });
  }

  getSafeHtml(htmlString: string): SafeHtml {
    const decodedHtml = decodeURIComponent(htmlString);
    const trustedHtml = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
    return trustedHtml;
  }


  getFormData(item: any): any {
    const embedFrom = item?.data?.quotes_templates_new_fields;
    if (embedFrom) {
      return {
        data: embedFrom?.map((el: any) => el?.field_styles),
        style: {
          color: embedFrom[0]?.field_styles?.colors,
          font_family: embedFrom[0]?.field_styles?.fontFamily,
          font_size: {
            head: embedFrom[0]?.field_styles?.headingSize,
            body: embedFrom[0]?.field_styles?.bodySize,
            helptxt: embedFrom[0]?.field_styles?.helpTextSize
          },
          logo:embedFrom[0]?.field_styles?.imageURL
        }
      }
    }
  }

  newtemFormValue() {
    this.newtemSubmit = true;
    if (this.newtemFrom?.invalid) {
      return;
    }
    this.newtemFrom?.controls['quotes_template_tags'].setValue(this.leadTags);
    if (this.dailogData.type === "create" ||
      this.dailogData.type === "clone" ||
      this.dailogData.type === "default" ||
      this.dailogData.type === "duplicate"|| 
      this.dailogData.type === "view" ||
      this.dailogData.type === "preview") 
      
      {
      this.create_Lead_Form_Value();
    }
    if (this.dailogData.type === "edit") {
      this.update_Lead_Form_Value();
    }
  }

  create_Lead_Form_Value() {
    const fields = this.dailogData?.data?.quotes_templates_new_fields.map((el:any)=>{return {
      field_name:el?.field_name,
      field_type:el?.field_type,
      field_order:el?.field_order,
      field_styles:el?.field_styles
    }});
    const payload = {
      ...this.newtemFrom.value,
      template_sub_types: 'custom',
      template_types:'Quote',
      html: this.dailogData?.data?.html ? this.dailogData?.data?.html : '',
      quotes_templates_new_fields: fields!==undefined?fields:[],
    };
    const tags = this.extractTags(payload.quotes_template_tags);
    if (tags.length > 0) {
      payload.quotes_template_tags = tags;
    }
    console.log(payload,'payload')  
    this.quoteTemplateService.create_Lead_Form(payload).subscribe(
      (res: any) => {
        if (res) {
          this.dialogRef.close();
          if(this.dailogData?.type1 =='quote_listing'){
            this.router.navigate([`dash/quote-template/formbuilder/${res?.data.id}`], { queryParams: { data: this.dailogData?.type1 } });

          }else{
            this.router.navigate([`dash/quote-template/formbuilder/${res?.data.id}`])
          }
        }
      },
      (error) => {
      }
    );
  }
  
  extractTags(quotesTemplateTags) {
    if (!quotesTemplateTags || !quotesTemplateTags.length) {
      return [];
    }
  
    return quotesTemplateTags
      .filter((tag) => tag.name)
      .map((tag) => ({ name: tag.name }));
  }
  

  update_Lead_Form_Value() {
    const payload = { ...this.newtemFrom.value, 
      id: this.updatetemp_id, 
      template_description:'',  
      quotes_templates_new_fields: [], 
      otherFields:{}, 
    }; 
    
    this.quoteTemplateService.update_Lead_Form(payload).subscribe((res: any) => {
      if (res) {
        this.dialogRef.close(this.submitType);
        if(this.submitType === 'update'){
          this.router.navigate([`dash/quote-template`]);
        }
        if(this.submitType === 'edit'){
          this.router.navigate([`dash/quote-template/formbuilder/${this.updatetemp_id}`]);
        }
      }
    }, (error) => { });
  }

  addNewTemForm() {
    this.newtemFrom = this.fb?.group({
      template_name: ['', Validators.required],
      // lable: ['', Validators.required],
      quotes_template_tags: ['']
    });
  }

  get newtemVal() {
    return this.newtemFrom?.controls;
  }

  add(event: MatChipInputEvent): void {
    const input = event?.input;
    const value = event?.value;
    if ((value || '').trim()) {
      const trimmedValue = value.trim();
      const isDuplicate = this.leadTags.some(tag => tag.name === trimmedValue);
      if (!isDuplicate) {
        this.leadTags.push({ name: trimmedValue });
      }
    }
    if (input) {
      input.value = '';
    }
  }

  remove(fruit): void {
    const index = this.leadTags.indexOf(fruit);
    if (index >= 0) {
      this.leadTags.splice(index, 1);
    }
  }

  patchForm(data: any) {
    this.newtemFrom.patchValue({
      template_name: data?.data?.template_name,
      quotes_template_tags: data?.data?.quotes_template_tags?.map((el: string) => { return { name: el } })
    });
    this.leadTags = data?.data?.quotes_template_tags;
    this.updatetemp_id = data?.data?.id;
  }


  paymentView() {
    console.log(this.dailogData, 'paymentView');
    
    this.paymentPage = true;
    setTimeout(() => {
      this.showThankYouDialog = true;
      setTimeout(() => {
        this.showThankYouDialog = false;
        this.paymentPage = false;
        this.dialogRef.close(this.savedSignatureDataUrl ? this.savedSignatureDataUrl :this.userName.value);

      }, 2000); // Close dialog after 5 seconds
    }, 500); // Show dialog after a slight delay (0.5 seconds) to allow previous dialog to close
  }
  

  

  createForm() {
    this.debitCardForm = this.fb.group({
      cardNumber: ['', [Validators.required, Validators.pattern(/^\d{16}$/)]],
      expiryDate: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{2}$/)]],
      cvc: ['', [Validators.required, Validators.pattern(/^\d{3}$/)]],
      name:['', Validators.required]
    });
  }

  get cardNumber() {
    return this.debitCardForm.get('cardNumber');
  }

  formatCardNumber(event: any) {
    let input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    input = input.substring(0, 16); // Limit to 16 characters

    // Add a space after every 4 numbers
    input = input.replace(/(\d{4})/g, '$1 ');

    // Update the form control value
    this.cardNumber.setValue(input);
}

  get name(){
   return this.debitCardForm.get('name')
  }
  get transferType(){
    return this.transferForm.get('transferType')
   }
   get accountNumber(){
    return this.transferForm.get('accountNumber')
   }
   get bankName(){
    return this.transferForm.get('bankName')
   }
   get email(){
    return this.transferForm.get('email')
   }
   get beneficiary(){
    return this.transferForm.get('beneficiary')
   }
 
  get expiryDate() {
    return this.debitCardForm.get('expiryDate');
  }

  get cvc() {
    return this.debitCardForm.get('cvc');
  }

  paymentMehtod(type){
    this.paymentMethod = type
  }


 
  formatTimestamp(isoTimestamp: string): any {
    // Parse ISO timestamp
    if(isoTimestamp){
      const date = new Date(isoTimestamp);
  
      // Create the custom format
      const formattedDate = this.datePipe.transform(date, 'EEE d MMM, y');
    
      return formattedDate;
  
    }
  }

  onPriceSelectionChange() {
    // this.tableDataItems()
    // You can perform any additional logic here based on the selected value
  }

  getSelectedImage(images: any[]): any | null {
    const selectedImageOrder1 = images.find(
      (image) => image.images_order === 1
    );
    const selectedImageOrder0 = images.find(
      (image) => image.images_order === 0
    );

    return selectedImageOrder1 || selectedImageOrder0 || null;
  }
  parsePrice(priceString: string | null): string {
    try {
      const priceObject = JSON.parse(priceString || '');
      return priceObject?.pricesA || ''; // Adjust the property name accordingly
    } catch (error) {
      console.error('Error parsing price:', error);
      return '';
    }
  }

  getImage(element){
    
  }

  async getCurrency() {
    const currencySubs = await this.businessProfileService.currency().subscribe((val: any) => {
      // Assuming that val.data is an array of currencies
      this.currencyArr = val.data;
      // console.log(this.crSymbol ,'this.currencyArr')
      const matchingCurrency = val.data.find(
        currency =>
          currency.currency_symbol === this.crSymbol 
      );
      // console.log(matchingCurrency,'matchingCurrencymatchingCurrency')

      this.selectedCurrency = { currency_code: matchingCurrency.currency_code, currency_symbol:matchingCurrency.currency_symbol };    
      // Check if currencyArr is empty or not
      if (this.currencyArr.length > 0) {
        this.selectedCurrency = this.currencyArr.find(x=>x.currency_code ==this.selectedCurrency?.currency_code );
        this.showCurrencySymbol=this.selectedCurrency
        this.showCurrencySymbolExchange=this.selectedCurrency

      } else {
      }
  
    });

    // const matchingCurrency = this.currencyArr.find(
    //   currency =>
    //     currency.currency_symbol === this.crSymbol
    // );  tu
    // this.selectedCurrency = matchingCurrency;

  }

  

  

  editContactInfo() {
    this._router.navigate(['/dash/contacts/edit/'+ this.contactId, { 'moduleTypeQuote': 'quoteListing',quote_id:this.update_id }]);

    // this._router.navigate(['/dash/contacts/edit/' + this.contactId]);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  closeDialogThankYou() {
    this.dialogRef.close(this.savedSignatureDataUrl ? this.savedSignatureDataUrl :this.userName.value);
  }

  getFilteredColumns(columns: any[], orientation: string): any[] {
    const filteredColumns = columns.filter(
      (column) => column.orientation === orientation
    );
    this.columnsToDisplay = filteredColumns.map((c) => c.name);
    return filteredColumns;
  }

  checkAddress(address: any): boolean {
    const houseNumber = address ? address.house_number : '';
    const streetName = address ? address.street_name : '';
    const postalCode = address ? address.postal_code : '';
    const country = address?.country ? address.country.name : '';
    const interiorNumber = address ? address.interior_number : '';
    const state = address.state ? address.state.name : '';
    const city = address.city_name ? address.city_name : '';

    return houseNumber || streetName || postalCode || country || interiorNumber || state || city;
  }

  getAddress(address: any): string {
    let addressVal = localStorage.getItem('address_format');
    this.addressArr = addressVal?.split(',');
    let addressData = '';
    if (this.addressArr && this.addressArr?.length > 0) {
      for (let i = 0; i < this.addressArr?.length; i++) {
        if (this.addressArr[i] === 'City Name') {
          addressData += (address?.city && address?.city + ', ') || '';
        }
        if (this.addressArr[i] === 'Street Name') {
          addressData +=
            (address?.street_name && address?.street_name + ', ') || '';
        }
        if (this.addressArr[i] === 'Country Name') {
          addressData += (address?.country && address?.country + ', ') || '';
        }
        if (this.addressArr[i] === 'Postal Code') {
          addressData +=
            (address?.postal_code && address?.postal_code + ', ') || '';
        }
        if (this.addressArr[i] === 'House Number') {
          addressData +=
            (address?.house_number && address?.house_number + ', ') || '';
        }
        if (this.addressArr[i] === 'Interior Number') {
          addressData +=
            (address?.interior_number && address?.interior_number + ', ') || '';
        }
        if (this.addressArr[i] === 'State Name') {
          addressData += (address?.state && address?.state + ', ') || '';
        }
      }
      return addressData;
    } else {
      return (
        (
          ((address?.house_number && address?.house_number + ', ') || '') +
          ((address?.interior_number && address?.interior_number + ', ') || '') +
            ((address?.street_name && address?.street_name + ', ') || '') +
            ((address?.country && address?.country + ', ') || '') +
            ((address?.city && address?.city + ', ') || '') +
          ((address?.state && address?.state + ', ') || '') +
          ((address?.postal_code && address?.postal_code + ', ') || '') 
        )?.trim() || ''
      );
    }
  }

  

  onExpandedChange(expandedMessage: boolean): void {
    this.widgetsPanel = expandedMessage;
  }
  onPriceExpandedChange(expandedMessage: boolean): void {
    this.pricePanel = expandedMessage;
  }

  infoDropdown() {
    this.inforDropdown = !this.inforDropdown;
  }


  getTableDataSource(tableIndex: number): MatTableDataSource<any> {
    return new MatTableDataSource<any>(this.tables[tableIndex]);
  }

  private calculateResult() {
    this.totalValue = this.lengthValue * this.widthValue;
  }

  addTotals(name,id,kk) {
    let totalDiscount = 0;
    let totalTaxPrice = 0;
    let grandTotal = 0;
    let Total=0;
          this.afterDiscount=0;
      this.aftertax=0;
      this.totalCost=0;
      this.profitmargin=0;
      this.salesCommission=0;

    if (this.addedItemsData.length > 1) {
      // let totalDiscount = 0;
      // let totalTaxPrice = 0;
      // let grandTotal = 0;
      // let Total=0;
      // afterDiscount:any;
      // aftertax:any;
      // totalCost:any;
      // profitmargin:any;
      // salesCommission:any;
    

      this.addedItemsData.forEach(item => {
          totalDiscount += item.total_discount;
          totalTaxPrice += item.tax_price;
          grandTotal += item.Total;
          this.afterDiscount +=item?.priceAfterDiscount;
          this.aftertax +=item?.priceAftertax;
          this.totalCost += item?.totalCostAll;
          this.profitmargin +=Number(item?.profitMargin);
          this.salesCommission+=item?.commissionSales;

      });
  
       Total = (grandTotal - totalDiscount) + totalTaxPrice;
  }
  else{
    totalDiscount +=(parseFloat(this.addedItemsData[0]?.total_discount));
    totalTaxPrice +=(parseFloat(this.addedItemsData[0]?.tax_price));
    grandTotal +=(parseFloat(this.addedItemsData[0]?.Total));
    Total=(grandTotal - totalDiscount)+ totalTaxPrice;
    this.afterDiscount +=this.addedItemsData[0]?.priceAfterDiscount;
    this.aftertax +=this.addedItemsData[0]?.priceAftertax;
    this.totalCost += this.addedItemsData[0]?.totalCostAll;
    this.profitmargin +=Number(this.addedItemsData[0]?.profitMargin);
    this.salesCommission+=this.addedItemsData[0]?.commissionSales;


  }
  this.profitmargin=this.profitmargin/this.addedItemsData?.length

  this.totalCost=this.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  this.aftertax=this.aftertax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  this.salesCommission=this.salesCommission.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  this.afterDiscount=this.afterDiscount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  this.profitmargin=this.profitmargin.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  this.totalCost1=this.totalCost
  this.aftertax1=this.aftertax
  this.salesCommission1=this.salesCommission
  this.afterDiscount1=this.afterDiscount
  this.profitmargin1=this.profitmargin




  let totalDiscountNew=totalDiscount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  let totalTaxPriceNew=totalTaxPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  let grandTotalNew=grandTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  let TotalNew=Total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

  this.actpageIndx = kk;
  let totalObject=this.dropListData[this.actpageIndx].items.find(x=>x.name =='Total')

    const index = this.dropListData[this.actpageIndx].items.findIndex(
      (item: any) => item?.name === totalObject.name && item?.id === totalObject.id
    );
    if (index !== -1) {
      this.dropListData[this.actpageIndx].items[index].subTotalAmount =grandTotalNew
      this.dropListData[this.actpageIndx].items[index].discountAmount =totalDiscountNew
      this.dropListData[this.actpageIndx].items[index].taxAmount =totalTaxPriceNew
      this.dropListData[this.actpageIndx].items[index].totalAmount =TotalNew



    }
   
    this.cdr.detectChanges();
            
}


  
  
  async matchKeys(firstArray, secondObject) {
    const matchedArray = [];
    if (secondObject && secondObject.data) {
        firstArray.forEach(obj => {
            Object.keys(secondObject.data).forEach(key => {
                if (obj.key === key) {
                    matchedArray.push({ [obj.name]: secondObject.data[key] });
                }
            });
        });
    }
    return matchedArray;
  }
  

  logTableData(name, id,type) {    
    // Clear existing rowData
    this.tableData = [];
    const index = this.dropListData[this.actpageIndx].items.findIndex(
      (item: any) => item?.name === name && item?.id === id
    );
  
    if (index !== -1) {
      const displayedColumns = this.dropListData[this.actpageIndx].items[index]?.tableDisplayedColumns;
  if(type =='rows'){
      // Iterate through each row and push the data to tableData array
      for (const row of this.dropListData[this.actpageIndx].items[index]?.tableDisplayedRows) {
        const rowDataItem: any = {};
        for (const column of displayedColumns) {
          rowDataItem[column.name] = row[column.name];
        }
        this.tableData.push(rowDataItem);
        this.dropListData[this.actpageIndx].items[index].tableDisplayedRows =this.tableData;

      }

  }
    }
  }
  initializeHeaderData() {
    for (let j = 0; j < this.selectedColumnCount; j++) {
      this.headerData.push(`Column ${j + 1}`);
    }
  }





 
  rawSelectedDate
  onDateChange(newDate: Date | string,name,id,kk,title) {
    this.selectedDate = newDate;
    this.formatSelectedDate(name,id,kk); // Call the formatting function
    console.log('Selected Date:', newDate);
    // You can perform additional actions with the selected date here
  }

  formatSelectedDate(name,id,kk) {
    this.actpageIndx=kk
    const index = this.dropListData[this.actpageIndx].items.findIndex(
      (item: any) => item?.name === name && item?.id === id
    );
   
    // Use the DatePipe to format the selected date
    if (this.selectedDate) {
      this.formattedSelectedDate = this.datePipe.transform(
        this.selectedDate,
        'EEE d MMM, y'
      );
    }
    if (index !== -1) {
      this.dropListData[this.actpageIndx].items[index].itemDate =
      this.formattedSelectedDate;
    }
  }
  @ViewChild('picker') picker: MatDatepicker<Date>;

  // formattedSelectedDate: string; // You should initialize this with your date value

  openDatePicker() {
    this.picker.open(); // Open the date picker
  }

  getCurrentFormattedDate(name,id,kk) {
    this.actpageIndx=kk
    const index = this.dropListData[this.actpageIndx].items.findIndex(
      (item: any) => item?.name === name && item?.id === id
    );
    const currentDate = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    if (index !== -1) {
      this.dropListData[this.actpageIndx].items[index].quoteCreationDate =
      formattedDate;
      this.formattedSelectedDate = formattedDate
      this.creationDate=true
      return formattedDate;
    }else return "";
  }

  commonFormat(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const currentDate = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    
    return formattedDate;


}



handleSignatureSaved(signatureDataUrl: string) {
  this.savedSignatureDataUrl=signatureDataUrl
  this.isSignatureEmpty = false;
  }

  ngAfterViewInit(): void {

    if (this.showCanvas && this.signaturePadCanvas) {
     this.signaturePad = new SignaturePad(this.signaturePadCanvas.nativeElement);
   }
   
   const paginationButton = this.tabGroup?._elementRef?.nativeElement?.querySelector('.mat-tab-header-pagination-after');
   const paginationbeforeButton = this.tabGroup?._elementRef?.nativeElement?.querySelector('.mat-tab-header-pagination-before');
    console.log('paginationButton', paginationButton);

   // Attach a click event listener to the pagination button
   if(paginationButton){
    this.renderer.listen(paginationButton, 'click', () => {
     // Check if the window width is less than or equal to 425
     if (window.innerWidth <= 425) {
     
      this.selectedTabIndex =  this.selectedTabIndex+1
      this.tabChanged(this.selectedTabIndex)
       // Add your logic to increase the tab here
     }
   });
   } else  if(paginationbeforeButton){
     this.renderer.listen(paginationButton, 'click', () => {
     // Check if the window width is less than or equal to 425
     if (window.innerWidth <= 425) {
      
      this.selectedTabIndex =  this.selectedTabIndex-1
      this.tabChanged(this.selectedTabIndex)
       // Add your logic to increase the tab here
     }
   });
   }
   
  //  this.calculateElementHeightDiv('drop-field')
 }

sourceOfContact(){
  const formContactListSubs = this.editContactService
  .formContactList()
  .subscribe((val: any) => {
    this.formContactList = val.data;
    
  });
this.subscriptions.push(formContactListSubs);

}


getEmailsAsString(): string {
  return this.merchantContactData?.merchant_customer_emails.map(customer => customer.email).join(', ');
}

onCurrencySelectionChange(event){
this.showCurrencySymbol=event.value
}

onCurrencySelectionChange2(event){
  this.showCurrencySymbolExchange=event.value
  this.afterDiscount1 =this.afterDiscount ;
  this.aftertax1 = this.aftertax;
  this.totalCost1 = this.totalCost;
  this.salesCommission1 = this.salesCommission;

  
}

onRateChange(value: string) {
  if (this.showCurrencySymbolExchange?.currency_symbol == this.selectedCurrency.currency_symbol) {
    // Handle case where currencies are the same, if needed
  } else {
    this.rateValue = Number(value);
    if(this.rateValue ==0){
      this.showCurrencySymbolExchange=this.showCurrencySymbol
      this.afterDiscount1 =this.afterDiscount ;
      this.aftertax1 = this.aftertax;
      this.totalCost1 = this.totalCost;
      this.salesCommission1 = this.salesCommission;
  
    }else{
      let afterDiscountNumber = parseFloat(this.afterDiscount.replace(/,/g, ''));
      let aftertaxNumber = parseFloat(this.aftertax.replace(/,/g, ''));
      let totalCostNumber = parseFloat(this.totalCost.replace(/,/g, ''));
      let salesCommissionNumber = parseFloat(this.salesCommission.replace(/,/g, ''));
  
      this.afterDiscount1 = afterDiscountNumber * this.rateValue;
      this.aftertax1 = aftertaxNumber * this.rateValue;
      this.totalCost1 = totalCostNumber * this.rateValue;
      this.salesCommission1 = salesCommissionNumber * this.rateValue;
  
      this.totalCost1=this.totalCost1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.aftertax1=this.aftertax1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.salesCommission1=this.salesCommission1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.afterDiscount1=this.afterDiscount1.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.profitmargin1=this.profitmargin.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  
    }
console.log(this.rateValue,'this.rateValue')
  }
}
  submited:boolean=false;
  get dStageValid():any {
    if(this.addQuoteStageForm?.controls)
    return this.addQuoteStageForm.controls;
  else return null
  }

  changeColor(event) {
    // this.leadDealModel.color = event?.target?.value;
    this.addQuoteStageForm?.controls['color'].setValue(event?.target?.value);
  }

  changeColor1(event) {
    // this.leadDealModel.color = event?.target?.value ? ('#' + event?.target?.value.replace(/#/g, "")) : null;
    this.addQuoteStageForm?.controls['color'].setValue(event?.target?.value ? ('#' + event?.target?.value.replace(/#/g, "")) : null);
  }

  saveQuoteStage(){
    if(!this.addQuoteStageForm?.valid){
       this.addQuoteStageForm.markAllAsTouched(); 
       return;
    }
    if(this.dailogData?.data?.id){
      this.addQuoteStageForm.value.id=this.dailogData?.data?.id;
    }
    this.dialogRef.close(this.addQuoteStageForm?.value);

  }

  changeImage(eventType: string) {
    if (eventType === 'mouseover') {
      this.chequeImage = this.otherImage;
    } else if (eventType === 'mouseout') {
      this.chequeImage = '../../../assets/icons/cheque_black.png';
    }
  }
  removeQuoteStage(stage){
    let itemDeletedata = {
      title: `Are you sure you want to delete`,
      delItems: true,
    };
    let payload=[{
      Id:stage?.id
    }]
    const dialogRef = this.dialog.open(MatConfirmDeleteComponent, {
      width: '350px',
      data: itemDeletedata,
    });
    dialogRef.afterClosed().subscribe(
      (confirmed: boolean) => {
        if (confirmed) {
          this.QuoteTemplateServices.delete_Quote_Stage(payload).subscribe({
            next:(resp)=>{
              this.dialogRef.close('delete');
            },
            error:(err)=>{
              console.log("got error : ",err);
            }
          })
        }
      },
    );
  }

  updateTaxIndex(data,i){
   
    if (this.addItemPriceTaxArr.length > 0) {
     let found = false;
     this.addItemPriceTaxArr.forEach(item => {
         if (item.taxData.customValueId === data.taxData.customValueId) {
             found = true;
         }
     });
 
     if (!found) {
         this.addItemPriceTaxArr.push(data);
     }
     }else{
       console.log('llllll');
       
      this.addItemPriceTaxArr.push(data)
     }
       
     
     
   }
   clearValue(){
     this.addItemPriceTaxArr = []
   }

   addPrice(event) {
    event.stopPropagation();
  
    const formattedValue = this.decimalPipe.transform(this.newPrice.value, '1.2-2');
    // Push the new price into the pricesArray
    this.pricesArrayNew.push({price:formattedValue,priceName:''});
    this.selectedPriceA=formattedValue
    this.newPriceA=formattedValue
    this.calculateProfit(formattedValue)
this.addPrices=true
    this.newPrice.reset();
    this.select.close();
  }
  
  
  

   updateIndex(data, i) {
    this.newDiscount=data
  }

  updatePrice(value: {price:string,priceName:string}) {
    this.newPriceA=value.price
    this.calculateProfit(value?.price)
  }

  calculateProfit(price) {
    const fix_cost_per_unit = parseFloat(this.fixCostUnit|| 0) || 0;
    const priceAString = price; // Get the price as a string
    if (typeof priceAString === 'string') {
        const priceA = parseFloat(priceAString.replace(/,/g, '')) || 0; // Remove commas and parse as a float

        if (fix_cost_per_unit || priceA) {
            let profit = priceA - fix_cost_per_unit;
            let profitMargin = ((profit * 100) / priceA).toFixed(2);

            const sales_commission = parseFloat(this.sales_commission) || 0;
            const sales_commission_type = this.sales_comm_type; // Assuming sales_commission_type is defined somewhere
            if (sales_commission !== 0 && (sales_commission_type === 2 || sales_commission_type === 1)) {
              if (sales_commission_type === 2) {
                  profit -= (profit * sales_commission / 100);  // Subtract as a percentage
              } else {
                  profit -= sales_commission;  // Subtract as a direct value
              }
              profitMargin = ((profit * 100) / priceA).toFixed(2);
          }
          

            this.profit = profit;
            this.profitMargn = profitMargin;
        } else {
            this.profitMargn = 100;
            this.profit = 100;
        }
    } else {
        this.profitMargn = 100;
        this.profit = 100;
    }
}


  increase(value,type){
   console.log(value);
   
    if(type == 'length'){
      this.lenghtValue = value
      this.lenghtValue++
    } else {
      this.width_Value = value
      this.width_Value++
    }
  }

  decrease(value,type){
    if(type == 'length'){
      
      this.lenghtValue = value
      if(this.lenghtValue>0){
        
        this.lenghtValue--
        
      }
     
    } else {
      this.width_Value = value
      if(this.width_Value>0){
        this.width_Value--
      }
    }
  }

  async updateItem() {

    if (this.newDiscount) {
        const discountValue = typeof this.newDiscount?.discounts === 'string' ? parseFloat(this.newDiscount?.discounts) : this.newDiscount?.discounts;
        if (!isNaN(discountValue)) {
            this.itemAddModel.item_prices[0].discount = discountValue.toString(); // Update discount
        }
    }

    // Check if newPriceA is provided and convert it to a number if it's a string
    if (this.newPriceA) {
        const priceAValue = this.newPriceA;
            // Parse the price string into an object
            const priceObject = JSON.parse(this.itemAddModel.item_prices[0].price);

            // Update pricesA property in the priceObject
            priceObject.pricesA = priceAValue;

            // Update the price property with the updated priceObject
            this.itemAddModel.item_prices[0].price = JSON.stringify(priceObject);
        
    }

    this.itemsListArr={data:[this.itemAddModel]}
    await this.tableDataItems([])
    // this.closeDialog()
}



  async editItemList(id) {
    
    const editItemListSubs = this.itemsService.iteamDataList(id).subscribe(async res => {
      this.itemAddModel = res.data; 
      this.itemCategoryId=res.data?.categoryId
      this.newItemData=res.data
      this.itemNewId=res.data?.id
      this.fixCostUnit=res.data?.item_prices[0]?.fix_cost_per_unit
      this.sales_commission=res.data?.item_prices[0]?.sales_commission
      this.sales_comm_type=res.data?.item_prices[0]?.sales_comm_type

//       console.log(this.itemAddModel,'llllllll');
           
//       this.isDeactivated=true
//       this.editItemsImages = this.itemAddModel?.item_images.sort((a, b) => {
//         if (a.images_order === null && b.images_order === null) {
//             return 0; // No change in order
//         }
//         if (a.images_order === null) {
//             return 1; // Put images with null order after others
//         }
//         if (b.images_order === null) {
//             return -1; // Put images with null order before others
//         }
//         // Both images have a defined images_order
//         if (a.images_order === 1) {
//             return -1; // Put images with images_order 1 at the top
//         }
//         if (b.images_order === 1) {
//             return 1; // Put images with images_order 1 at the top
//         }
//         return a.images_order - b.images_order; // Sort by images_order
//     });
//     const userImageData=this.editItemsImages[0]
  
//       let selectedCat=this.cateogryListArr.find(item=>item.id==this.inputData.categoryId);

//       if(this.inputData.categoryId){
//         this.getCatId(this.inputData.categoryId)
//         await this.subCategory()
//       }
      
//       let selectedSubCat  = this.subCategoryListArr.find(item=> {return item.id == this.itemAddModel.subcategory_id});

//       this.unitSelect.patchValue(this.itemAddModel?.unit)
//       this.itemType.patchValue(this.itemAddModel?.item_coming_from)
//       this.userBasicInfoForm.patchValue({
//         categoryId:selectedCat?.name,
//         subCategoryId:selectedSubCat?.name

//       })
      
//       this.addEditItemCodeArr = this.itemAddModel.childitems;

//       const bundles = this.itemAddModel.bundles;
//       let bundlesListData;
//       for (let i = 0; i < bundles?.length; ++i) {
//         bundlesListData = { 'item_bundles': { 'bundleId': bundles[i]['id'], 'title': bundles[i]['title'], 'hide_price': bundles[i]['item_bundles']['hide_price'] } }
//         this.selectedBundle.push(bundlesListData);
//       }
//       this.itemAdditionalDetailsArr = this.itemAddModel.item_additional_details
//       const warehouses = this.itemAddModel.warehouses;
//       let warehousesListData;
//       for (let i = 0; i < warehouses?.length; ++i) {
//         this.addWarehouseArr.push({status:1, 'warehouseId': warehouses[i]['id'] ,'name': warehouses[i]['name'],'items': warehouses[i]['item_warehouses']['items'] });
//       }
//       // ===================== Item_price_discounts  ===========================
let discountValue =''
      if(this.itemAddModel?.item_prices[0]['discount'] !==null){
         discountValue = this.itemAddModel?.item_prices[0]['discount'].split(',');
      }      let discountValueArr = [];
      for (let i = 0; i < discountValue.length; ++i) {
      if (discountValue[i] !== '') { 
      discountValueArr.push({ 'discounts': parseInt(discountValue[i], 10) });
    }
    }

      this.addDiscountsArr = discountValueArr;
      this.addDiscountsArr.sort((a, b) => a.discounts - b.discounts);
      this.applyDiscount={discounts:this.dailogData?.applyDiscount}
      this.newDiscount=this.applyDiscount
      this.cd.detectChanges()


      // const itemPrices = JSON.parse(this.itemAddModel?.item_prices[0]['price']);
      // const sanitizedPrices = Object.keys(itemPrices).reduce((result, key) => {
      //   const formattedPrice = itemPrices[key].replace(',', ''); // Remove commas
      //   result[key] = formattedPrice;
      //   return result;
      // }, {});      
      // this.itemPriceForm.patchValue(sanitizedPrices);
      
//       this.numberField.get('numberValue').setValue(Number(this.itemAddModel.unit_quantity))
let addedNewOne=JSON.parse(this.itemAddModel?.item_prices[0]['price'])
this.newItemPriceList=[{...addedNewOne,pricesE:this.dailogData?.newPriceAdded ||''}]
this.selectedPriceA=this.dailogData?.newPriceAdded?this.dailogData?.newPriceAdded:addedNewOne.pricesA
this.newPriceA=this.selectedPriceA
this.pricesArrayNew = [];
// Assuming your original array is named 'newItemPriceList'

  if (Array.isArray(this.newItemPriceList) && this.newItemPriceList.length > 0) {
    // Push prices into the pricesArray
    if(this.newItemPriceList[0].pricesA !==''){
      this.pricesArrayNew.push({price:this.newItemPriceList[0].pricesA,priceName:'Price A'});
    }
    if(this.newItemPriceList[0].pricesB !==''){
      this.pricesArrayNew.push({price:this.newItemPriceList[0].pricesB,priceName:'Price B'});      
    }
    if(this.newItemPriceList[0].pricesC !==''){
      this.pricesArrayNew.push({price:this.newItemPriceList[0].pricesC,priceName:'Price C'});
    }
    if(this.newItemPriceList[0].pricesD !==''){
      this.pricesArrayNew.push({price:this.newItemPriceList[0].pricesD,priceName:'Price D'});
    }
    if(this.newItemPriceList[0].pricesE !==''){
      this.pricesArrayNew.push({price:this.newItemPriceList[0].pricesE,priceName:''}); 
    }    
    console.log(this.newItemPriceList,'this.newItemPriceList')
  }
      this.cd.detectChanges()
      this.calculateProfit(this.newPriceA)


        let itemsPrice = JSON.parse(this.itemAddModel?.item_prices[0]['price'])
      if(itemsPrice){
        this.itemPriceList = itemsPrice
        
      }
      
    
//       this.itemAddModel.price = JSON.parse(this.itemAddModel?.item_prices[0]['price']);
//       this.comments = this.itemAddModel.comments
//       this.itemAddModel.min_price = this.itemAddModel?.item_prices[0]['min_price'];
//       this.itemAddModel.currency_id = this.itemAddModel?.item_prices[0]['currency_id'];
//       this.userBasicInfoForm.get('currency_id')?.patchValue(Number(this.itemAddModel?.item_prices[0]['currency_id']))
//       this.itemAddModel.advance_unit_cal = this.itemAddModel?.item_prices[0]['advance_unit_cal'];
//       this.itemAddModel.sales_commission = this.itemAddModel?.item_prices[0]['sales_commission'];
//       this.itemAddModel.sales_comm_type = this.itemAddModel?.item_prices[0]['sales_comm_type'];
//       this.itemAddModel.fix_cost_per_unit = this.itemAddModel?.item_prices[0]['fix_cost_per_unit'];
//       this.itemAddModel.categoryId = this.inputData.categoryId
//       // ===================== Item_price  ===========================

      const item_prices_tax = this.itemAddModel?.item_prices_taxs;
let itemPricesTaxListData;

if (item_prices_tax) {
  for (let i = 0; i < item_prices_tax.length; ++i) {
    if (
      item_prices_tax[i] &&
      item_prices_tax[i]['taxe_value'] &&
      item_prices_tax[i]['custom_value'] &&
      item_prices_tax[i]['custom_value']['name'] &&
      item_prices_tax[i]['customValueId']
    ) {
      itemPricesTaxListData = {
        'taxe_value': item_prices_tax[i]['taxe_value'],
        'taxData': {
          'name': item_prices_tax[i]['custom_value']['name'],
          'customValueId': item_prices_tax[i]['customValueId']
        }
      };
      this.addItemPriceTaxArr.push(itemPricesTaxListData);
    }
  }
}

this.allTaxesList = this.addItemPriceTaxArr

      this.addItemPriceTaxArr.sort((a,b) => a.taxe_value - b.taxe_value);

    });
    this.subscriptions.push(editItemListSubs);  
  }

  compareFn(option1, option2) {
    return option1 && option2 ? option1.discounts === option2.discounts : option1 === option2;
  }


  openDropdown(event: MouseEvent) {
    event.stopPropagation(); // Prevents the event from propagating to parent elements
    this.salesTaxSelect.nativeElement.click(); // Programmatically trigger the click event of the mat-select
  }


  tableDataItems(data: any = []) {
    const tableData = this.itemsListArr.data.flatMap((item) => {
      const selectedImage = this.getSelectedImage(item.item_images);
      
      // Extract values from the item object
      const fix_cost_per_unit = parseFloat(item.item_prices[0]?.fix_cost_per_unit) || 0;
      const priceAStringified = item.item_prices[0]?.price as any; // Assuming it's a JSON-stringified string
      const sales_commission = parseFloat(item.item_prices[0]?.sales_commission) || 0;
      const sales_commission_type = item.item_prices[0]?.sales_comm_type;
  
      let profit = 0;
      let profitMargin = 0;
      if (typeof priceAStringified === 'string') {
        try {
          const priceAObj = JSON.parse(priceAStringified);
          this.itemPrices = JSON.parse(priceAStringified);
          const priceA = parseFloat(priceAObj.pricesA.replace(/,/g, '')) || 0;
          if (fix_cost_per_unit || priceA) {
            profit = priceA - fix_cost_per_unit;
            profitMargin = (profit * 100) / priceA;
  
            if (sales_commission !== 0 && (sales_commission_type === 2 || sales_commission_type === 1)) {
              if (sales_commission_type === 2) {
                profit -= profit * (sales_commission / 100); // Subtract as a percentage
              } else {
                profit -= sales_commission; // Subtract as a direct value
              }
              profitMargin = (profit * 100) / priceA;
            }
          } else {
            profitMargin = 100;
            profit = 100;
          }
        } catch (error) {
          console.error('Error parsing priceAStringified:', error);
        }
      } else {
        profitMargin = 100;
        profit = 100;
      }
  
      const priceAObj = JSON.parse(priceAStringified);
      const price = parseFloat(priceAObj.pricesA.replace(/,/g, '')) || 0;
      let discount = 0.00;
      if (item.item_prices[0]?.discount) {
        const discountValue = item.item_prices[0].discount; // Access item_prices[0].discount
        discount = parseFloat(discountValue) / 100 || 0.00; // Correctly replace commas
      }
      
      let item_prices_tax = data?.length ? data : item.item_prices_taxs;
      let totalTax = 0;
      item_prices_tax.forEach((taxItem) => {
        if (taxItem.taxe_value) {
          const taxValue = parseFloat(taxItem.taxe_value.replace('%', '')) / 100 || 0.0;
          totalTax += taxValue;
        }
      });
  
      let tax = 0.0;
      if (item.item_prices_taxs[0]?.taxe_value) {
        const taxValue = Array.isArray(item.item_prices_taxs[0].taxe_value)
          ? item.item_prices_taxs[0].taxe_value[0]
          : item.item_prices_taxs[0].taxe_value;
        tax = parseFloat(taxValue.replace('%', '')) / 100 || 0.0;
      }
  
      const discPrice = price - price * discount;
      const quantity = parseInt(item.unit_quantity, 10);
      const tax_price = (price * tax) * quantity;
      const total_discount = (price * discount) * quantity;
      const perItemTax = price * tax;
      const totalTaxItem = price * totalTax;
      const perItemDisc = price * discount;
      const totalPrice = price * quantity;
      const priceAfterDiscount = totalPrice - total_discount;
      const priceAftertax = totalPrice + tax_price;
      const totalCost = fix_cost_per_unit * quantity;
      const commissionSales = quantity * sales_commission;
      const discountArray = item?.item_prices[0]?.discount;
      const firstDiscount = discountArray || 0;
      const discountPercentage = firstDiscount / 100;
      const unitQuantity = item?.unit_quantity || 0;
      const description = item?.description ? item.description.replace(/<\/?p>/g, '') : '';
      const total = (this.parsePrice(priceAStringified) as any) * (unitQuantity as number);
  
      let result = {
        'Applicable taxes': item_prices_tax.map(tax => ({
          name: tax?.custom_values ? tax?.custom_values.name : tax?.custom_value?.name,
          value: tax?.taxe_value
        })).map(tax => `${tax.name} (${tax.value}%)`).join(','),
        Comments:item?.comments?.length? item?.comments?.map((comment) => ({
          id: comment?.id,
          title: comment?.title,
          item_comments: comment?.item_comments,
        })):[],
        Description: description,
        perItemTax: perItemTax,
        newAddedPrice: this.newPriceA,
        totalTaxItem: totalTaxItem,
        perItemDisc: perItemDisc,
        newPricesArray: this.pricesArrayNew,
        Discount: `${firstDiscount}%`,
        perItemPrice: price,
        Item_code: item?.code || '',
        applyDiscount: this.newDiscount?.discounts,
        Item_image: selectedImage ? this.imageUrl + selectedImage.image : this.staticLogo,
        id: item?.id,
        Quantity: item?.unit_quantity || '',
        Item_name: item?.name || '',
        Tax_exempt: item?.item_prices_taxs[0]?.name,
        'Video URL': item?.video_url,
        'Website URL': item?.website_url,
        description: description,
        item: {
          name: item?.name || '',
          description: description,
        },
        Price: this.parsePrice(priceAStringified),
        discount: `${firstDiscount}%`,
        Total: totalPrice || 0,
        discPrice: discPrice.toFixed(2),
        created_at: item.created_at || '',
        category: item?.category?.name,
        categoryId: item?.category?.id,
        subCategory: item?.subcategory?.name,
        subCategoryId: item?.subcategory?.id,
        Unit: item?.unit || '',
        total_discount: total_discount || 0,
        tax_price: tax_price || 0,
        comments: item?.comments?.map((comment) => ({
          id: comment.id,
          title: comment.title,
          item_comments: comment.item_comments,
        })),
        itemsComing: [
          { itemPrice: this.itemPrices.pricesA, comingFrom: 'Mexico', items: '25', itemDiscount: '5%' },
          { itemPrice: this.itemPrices.pricesB, comingFrom: 'Canada', items: '15', itemDiscount: '8%' },
          { itemPrice: this.itemPrices.pricesC, comingFrom: 'USA', items: '9', itemDiscount: '6%' },
          { itemPrice: this.itemPrices.pricesD, comingFrom: 'USA', items: '9', itemDiscount: '6%' },
        ],
        picture: selectedImage ? this.imageUrl + selectedImage.image : this.staticLogo,
        stockStatus: 'In Stock',
        totalCost: fix_cost_per_unit,
        salesCommission: `$${sales_commission.toFixed(2)}`,
        profitMargin: `${profitMargin.toFixed(2)}`,
        priceAfterDiscount: priceAfterDiscount,
        priceAftertax: priceAftertax,
        totalCostAll: totalCost,
        commissionSales: commissionSales,
        taxes: item_prices_tax.map(tax => ({
          name: tax?.custom_value?.name,
          value: tax?.taxe_value
        }))
      };
  
      // Add custom field data dynamically
      if (item?.items_custom_field_datas?.length) {
        item.items_custom_field_datas.forEach(customField => {
          if (customField.itemsCustomField && customField.value) {
            result[customField.itemsCustomField.name] = customField?.field_type_id == '3' ? this.formatTimestamp(customField.value) : customField.value;
          }
        });
      }
  
      return result;
    });
  
    this.itemsListArr = tableData;
    this.dialogRef.close(tableData);
  }
  

  editItemListNew() {
    



    const dialogData: any = {edit:'quotes'};
    dialogData.categoryId = this.itemCategoryId;
    dialogData.itemsEditButtonId = this.itemCategoryId;
    dialogData.id = this.itemNewId;
    if(this.newItemData.isDeactivated=="1"||this.newItemData.isDeactivated==true)dialogData.isDeactivated=true;
    else dialogData.isDeactivated=false;
    const dialogRef = this.dialog.open(AddEditItemComponent, {
      width: "870px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(async (dialogResult) => {
      if (dialogResult) {
        let transformedData=[]
        if (dialogResult.tex?.length) {
          transformedData = dialogResult.tex.map(item => ({
              custom_values: { name: item?.taxData ? item?.taxData?.name : item?.name },
              taxe_value: item?.taxe_value
          }));
      }
       
        this.itemsListArr={data:[dialogResult]}
        await this.tableDataItemsNew(transformedData)
        this.dialogRef.close(true)
        this.dialogRef.close(this.itemsListArr);
      }
    });
  }

  tableDataItemsNew(data:any=[]) {
    const tableData = this.itemsListArr.data.flatMap((item) => {
      const selectedImage = this.getSelectedImage(item.item_images);
      // Extract values from the item object
      const fix_cost_per_unit = parseFloat(item.item_prices[0]?.fix_cost_per_unit) || 0;
      const priceAStringified = item.item_prices[0]?.price as any; // Assuming it's a JSON-stringified string
      const sales_commission = parseFloat(item.item_prices[0]?.sales_commission) || 0;
      const sales_commission_type = item.item_prices[0]?.sales_comm_type;
  
      let profit = 0;
      let profitMargin = 0;
  
      if (typeof priceAStringified === 'string') {
        try {
          const priceAObj = JSON.parse(priceAStringified);
          this.itemPrices = JSON.parse(priceAStringified);
          const priceA = parseFloat(priceAObj.pricesA.replace(/,/g, '')) || 0;
          if (fix_cost_per_unit || priceA) {
            profit = priceA - fix_cost_per_unit;
            profitMargin = (profit * 100) / priceA;
  
            if (sales_commission !== 0 && (sales_commission_type === 2 || sales_commission_type === 1)) {
              if (sales_commission_type === 2) {
                profit -= profit * (sales_commission / 100); // Subtract as a percentage
              } else {
                profit -= sales_commission; // Subtract as a direct value
              }
              profitMargin = (profit * 100) / priceA;
            }
          } else {
            profitMargin = 100;
            profit = 100;
          }
        } catch (error) {
          console.error('Error parsing priceAStringified:', error);
        }
      } else {
        profitMargin = 100;
        profit = 100;
      }

      const priceAObj = JSON.parse(priceAStringified);
      let price
      if(this.dailogData.newPriceAdded !==undefined || !this.dailogData.newPriceAdded){
        price = parseFloat(this.dailogData.newPriceAdded.replace(/,/g, '')) || 0;

      }else{
        price = parseFloat(priceAObj.pricesA.replace(/,/g, '')) || 0;

      }
       let discount = 0.00;
      if (this.dailogData.applyDiscount !==undefined ){
        const discountValue = typeof this.newDiscount?.discounts === 'string' ? parseFloat(this.newDiscount?.discounts) : this.newDiscount?.discounts;
        if (!isNaN(discountValue)) {
          discount = discountValue; 
      }
      }else{
        if (item.item_prices[0]?.discount?.length) {
          const discountValue = Array.isArray(item.item_prices[0].discount) ? item.item_prices[0].discount[0] : item.item_prices[0].discount;
          discount = parseFloat(discountValue.replace('%', '')) / 100 || 0.00;
      }
      }
            
       let item_prices_tax = data?.length ? data : item.item_prices_taxs;
       let totalTax = 0;
       item_prices_tax.forEach((taxItem) => {
           if (taxItem.taxe_value) {
               const taxValue = parseFloat(taxItem.taxe_value.replace('%', '')) / 100 || 0.0;
               totalTax += taxValue;
           }
       });
let tax = 0.0;
if (item.item_prices_taxs[0]?.taxe_value) {
  const taxValue = Array.isArray(item.item_prices_taxs[0].taxe_value)
    ? item.item_prices_taxs[0].taxe_value[0]
    : item.item_prices_taxs[0].taxe_value;
  tax = parseFloat(taxValue.replace('%', '')) / 100 || 0.0;
}
let discPrice
let total_discount
let totalPrice
let perItemDisc
const quantity = parseInt(item.unit_quantity, 10);
if (this.dailogData.applyDiscount !==undefined ){
  total_discount = (price * discount / 100) * quantity;
  discPrice = price * discount / 100;
  totalPrice = price * quantity;
  perItemDisc = price * discount / 100;


}else{
  total_discount = (price * discount)*quantity
  totalPrice = price * quantity;
  discPrice =  price * discount;
  perItemDisc = price*discount

}
  // Parse quantity
  const tax_price = (price * tax)*quantity;
  const perItemTax = price * tax
  const totalTaxItem=price*totalTax
  // Calculate totalPrice
  const priceAfterDiscount=totalPrice-total_discount 
  const priceAftertax=totalPrice+tax_price 
  const totalCost=fix_cost_per_unit * quantity
const commissionSales=quantity * sales_commission

      const discountArray = item?.item_prices[0]?.discount;
      const firstDiscount = discountArray || 0;
      const discountPercentage = firstDiscount / 100;
      const unitQuantity = item?.unit_quantity || 0;
      // const discPrice = (this.parsePrice(priceAStringified) as any) * (unitQuantity as number) * (1 - discountPercentage);    
      const description = item?.description ? item.description.replace(/<\/?p>/g, '') : '';
      const total=(this.parsePrice(priceAStringified) as any) * (unitQuantity as number);
      let result= {
        'Applicable taxes':item_prices_tax.map(tax => ({
          name: tax?.custom_values?tax?.custom_values.name:tax?.custom_value?.name,
          value: tax?.taxe_value
        })).map(tax => `${tax.name} (${tax.value}%)`).join(','),
        Comments:item?.comments?.length? item?.comments?.map((comment) => ({
          id: comment?.id,
          title: comment?.title,
          item_comments: comment?.item_comments,
        })):[],
        Description:description,
        perItemTax:perItemTax,
        totalTaxItem:totalTaxItem,
        newAddedPrice:this.newPriceA,
        perItemDisc:perItemDisc,
        applyDiscount: this.newDiscount?.discounts,
        Discount:`${firstDiscount}%`,
        perItemPrice: price,
        Item_code:item?.code || '',
        Item_image:selectedImage ? this.imageUrl + selectedImage.image : this.staticLogo,
        id: item?.id,
        Quantity: item?.unit_quantity || '',
        Item_name: item?.name || '',
        Tax_exempt:item?.item_prices_taxs[0]?.name,
        'Video URL':item?.video_url,
        'Website URL':item?.website_url,
        description: description,
        item: {
          name: item?.name || '',
          description: description,
        },
        Price: this.parsePrice(priceAStringified),
        discount: `${firstDiscount}%`,
        Total:totalPrice|| 0,
        discPrice: discPrice.toFixed(2),
        created_at: item.created_at || '',
        category: item?.category?.name,
        categoryId: item?.category?.id,
        subCategory: item?.subcategory?.name,
        subCategoryId: item?.subcategory?.id,
        Unit: item?.unit || '',
        total_discount: total_discount || 0,
        tax_price: tax_price || 0,
        comments: item?.comments?.map((comment) => ({
          id: comment.id,
          title: comment.title,
          item_comments: comment.item_comments,
        })),
        itemsComing: [
          { itemPrice: this.itemPrices.pricesA, comingFrom: 'Mexico', items: '25', itemDiscount: '5%' },
          { itemPrice: this.itemPrices.pricesB, comingFrom: 'Canada', items: '15', itemDiscount: '8%' },
          { itemPrice: this.itemPrices.pricesC, comingFrom: 'USA', items: '9', itemDiscount: '6%' },
          { itemPrice: this.itemPrices.pricesD, comingFrom: 'USA', items: '9', itemDiscount: '6%' },
        ],
        picture: selectedImage ? this.imageUrl + selectedImage.image : this.staticLogo,
        stockStatus: 'In Stock',
        totalCost: fix_cost_per_unit, 
        salesCommission: `$${sales_commission.toFixed(2)}`, 
        profitMargin: `${profitMargin.toFixed(2)}`, 
        priceAfterDiscount:priceAfterDiscount,
        priceAftertax:priceAftertax,
        totalCostAll:totalCost,
        commissionSales:commissionSales,
        taxes: item_prices_tax.map(tax => ({
          name: tax?.custom_values?.name,
          value: tax?.taxe_value
      }))
      
        
      };

       // Add custom field data dynamically
   if (item?.items_custom_field_datas?.length) {
    item.items_custom_field_datas.forEach(customField => {
      if (customField.itemsCustomField && customField.value) {
        result[customField.itemsCustomField.name] = customField?.field_type_id == '3' ? this.formatTimestamp(customField.value) : customField.value;
      }
    });
  }

  return result;


    });
  
    this.itemsListArr=tableData;
    // if(this.itemsListArr){
    //   this.getCateogryList(
      
    //     ).then(() => {
    //       this._autoCompleteSearchControlForCategory();
    //       // this._autoCompleteSearchControlForSubCategory();
    //     });                // this.filteredCetegories = this._filter(this.fruitCtrl?.value)

    // }
  }

  tabChanged(tabIndex: number,data:any=''): void {
    console.log(tabIndex, 'tabffffffffffffffIndex');
   this.selectedTabIndex = tabIndex; }

   getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }

}
