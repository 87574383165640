<div class="row">
    <form name="form" [formGroup]="fg">
        <div formArrayName="lineItems" *ngFor="let item of fg?.get('lineItems')['controls']; let i = index" >
            <ng-container *ngIf="!isDisabled">
               
                <div [formGroupName]="i" class="position-relative">
                    <div
    class="d-inline-block fw-500 text-1 align-top mt-5"
    [ngClass]="{ isIconShow: 'ms-2' }"
  >
    {{ "Address" }}
</div><span *ngIf="addressRequired" class="color-6">*</span>
                    <div class="mobile_name col-3  my-2 flex align-items-center" *ngIf="needOtherFields && field_address.type?.length">
                        <mat-form-field appearance="outline" floatLabel="always" id="AddressTitleInput{{ i }}">
                          <mat-select class="text-1" placeholder="Select Address Name"
                            [ngModelOptions]="{standalone: true}" 
                            (ngModelChange)="changeAddField($event, 'addTitle', i)">
          
                            <mat-option class="text-1" value="">
                              {{"CONTACT_PROFILE.select_address_name" | translate}}
                            </mat-option>
                            <mat-option id="option_num" class="text-1 " *ngFor="let formContactList of field_address.type; let i = index"
                              [value]="formContactList">
                              
                              {{formContactList}}
                            </mat-option>
                       
                          </mat-select>
                        </mat-form-field>
                        <div class="address-action col-6" *ngIf="needOtherFields">
                         
                    
                            <button class="nav-link add-btn  color-4 f-w-500" (click)="removeAddress(i)"
                                  *ngIf="customerAddressArr.length > 1">
                                  <i class="fas fa-times me-1"></i>{{ "CONTACT_PROFILE.Delete_Address" | translate }}
                                </button>
                          </div>
                      </div>
                    <input 
                        formControlName="formattedAddress"
                        id="address"
                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                        googlePlaces type="text" class="form-control address"
                        placeholder="{{ 'Type to search' }}"
                        [country]="fg.get('lineItems').get(i.toString()).get('country')"
                        [region]="fg.get('lineItems').get(i.toString()).get('state')"
                        [province]="fg.get('lineItems').get(i.toString()).get('province')"
                        [city]="fg.get('lineItems').get(i.toString()).get('city')"
                        [postal_code]="fg.get('lineItems').get(i.toString()).get('postal_code')"
                        [street_name]="fg.get('lineItems').get(i.toString()).get('street_name')"
                        [streetNumber]="fg.get('lineItems').get(i.toString()).get('house_number')"
                        [latitude]="fg.get('lineItems').get(i.toString()).get('latitude')"
                        [longitude]="fg.get('lineItems').get(i.toString()).get('longitude')"
                        [formattedAddress]="fg.get('lineItems').get(i.toString()).get('formattedAddress')" />
                </div>
                <div class="row" [formGroupName]="i" [ngClass]="{ 'ms-3': paddingLeft }" *ngIf="needOtherFields">
                    <ng-container *ngFor="let addressform of addressFormateArrView">
                        <ng-container *ngIf="addressform == 'Country Name'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.Country" | translate }}:</div>
                                <div>
                                    <input [id]="'country' + tempIndex" class="w-full input px-2 py-2" name="country" formControlName="country"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.COUNTRY' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'City Name'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.State" | translate }}:</div>
                                <div>
                                    <input [id]="'state' + tempIndex" class="w-full input px-2 py-2" name="state" formControlName="state"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.STATE' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'City Name'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.City" | translate }}:</div>
                                <div>
                                    <input [id]="'city' + tempIndex" class="w-full input px-2 py-2" name="city" formControlName="city"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.CITY' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Postal Code'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.Postal_Code" | translate }}:</div>
                                <div>
                                    <input [id]="'postal_code' + tempIndex" class="w-full input px-2 py-2" name="postal_code" formControlName="postal_code"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}" 
                                        placeholder="{{'GENRIC_PLACEHOLDER.POSTAL_CODE' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Street Name'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.Street_Name" | translate }}:</div>
                                <div>
                                    <input [id]="'street_name' + tempIndex" class="w-full input px-2 py-2" name="street_name" formControlName="street_name"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.STREET_NAME' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'House Number'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.House_number" | translate }}:</div>
                                <div>
                                    <input  [id]="'house_number' + tempIndex" class="w-full input px-2 py-2 house_number" name="house_number" formControlName="house_number"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.HOUSE_NO' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Interior Number'">
                            <div class="col-4 mt-2">
                                <div class="font-medium resp-name-label">{{ "CONTACT_PROFILE.Interior_number" | translate }}:</div>
                                <div>
                                    <input [id]="'interior_number' + tempIndex" class="w-full input px-2 py-2" name="interior_number" formControlName="interior_number"
                                        [ngStyle]="{'font-size':customerAddressStyle?.fontsize}"
                                        placeholder="{{'GENRIC_PLACEHOLDER.INTERIOR_NO' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                
            </ng-container>
           
            <ng-container *ngIf="isDisabled">
                <div [formGroupName]="i" class="position-relative">
                    <input 
                        disabled="true"
                        formControlName="formattedAddress"
                        id="address"
                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                        googlePlaces type="text" class="form-control"
                        placeholder="{{ 'Type to search' }}"
                        [country]="fg.get('lineItems').get(i.toString()).get('country')"
                        [region]="fg.get('lineItems').get(i.toString()).get('state')"
                        [province]="fg.get('lineItems').get(i.toString()).get('province')"
                        [city]="fg.get('lineItems').get(i.toString()).get('city')"
                        [postal_code]="fg.get('lineItems').get(i.toString()).get('postal_code')"
                        [street_name]="fg.get('lineItems').get(i.toString()).get('street_name')"
                        [streetNumber]="fg.get('lineItems').get(i.toString()).get('house_number')"
                        [latitude]="fg.get('lineItems').get(i.toString()).get('latitude')"
                        [longitude]="fg.get('lineItems').get(i.toString()).get('longitude')"
                        [formattedAddress]="fg.get('lineItems').get(i.toString()).get('formattedAddress')" />
                </div>
                <div class="row" [formGroupName]="i" [ngClass]="{ 'ms-3': paddingLeft }" *ngIf="needOtherFields">
                    <ng-container *ngFor="let addressform of addressFormateArrView">
                        <ng-container *ngIf="addressform == 'Country Name'">
                            <div class="col-4 mt-2">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.Country" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="country" class="form-control" name="country" formControlName="country"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.COUNTRY' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'City Name'">
                            <div class="col-4 mt-2 px-0">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.State" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="state" class="form-control" name="state" formControlName="state"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.STATE' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'City Name'">
                            <div class="col-4 mt-2">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.City" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="city" class="form-control" name="city" formControlName="city"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.CITY' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Postal Code'">
                            <div class="col-4 mt-2">
                                <p class="d-inline-block align-top mb-0">
                                    {{ "CONTACT_PROFILE.Postal_Code" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="postal_code" class="form-control" name="postal_code" formControlName="postal_code"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.POSTAL_CODE' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Street Name'">
                            <div class="col-4 mt-2">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.Street_Name" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="street_name" class="form-control" name="street_name" formControlName="street_name"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.STREET_NAME' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'House Number'">
                            <div class="col-4 mt-2">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.House_number" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="house_number" class="form-control" name="house_number" formControlName="house_number"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.HOUSE_NO' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="addressform == 'Interior Number'">
                            <div class="col-4 mt-2 px-0">
                                <p class="d-inline-block align-top mb-0"
                                    [ngStyle]="{'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color}">
                                    {{ "CONTACT_PROFILE.Interior_number" | translate }}
                                </p>
                                <div>
                                    <input disabled="true" id="interior_number" class="form-control" name="interior_number" formControlName="interior_number"
                                        [ngStyle]="{'border-color':customerAddressStyle?.bordercolor,'font-size':customerAddressStyle?.fontsize, 'font-family':customerAddressStyle?.fontfamily, 'color':customerAddressStyle?.color }"
                                        placeholder="{{'GENRIC_PLACEHOLDER.INTERIOR_NO' | translate}}" />
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                  
                </div>

                
            </ng-container>
           
            
        </div>
    </form>

    <div class="address-action" *ngIf="needOtherFields">
        <button style="background: transparent;border: none;" type="button" class="add-btn add-btn-plain-link mt-3 add-address" (click)="addAdditionalAddress($event)">
          <i class="fas fa-plus-circle me-1"></i>
          {{ "CONTACT_PROFILE.Add_Additional_Address" | translate }}
        </button>


      </div>
</div>