
    <div *ngIf="inputType =='date' && dataType == '1'">
        <mat-form-field 
            appearance="outline" 
            floatLabel="always">
            <input #input matInput [matDatepicker]="dateName" name="{{inputName}}"
            [min]="minDate" [value]="fcontrol?.value" (dateChange)="onChange($event)" (click)="dateName.open()" readonly>
            <mat-datepicker-toggle matSuffix [for]="dateName"></mat-datepicker-toggle>
            <mat-datepicker #dateName></mat-datepicker>
            <mat-error *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
                {{errorMessage_required ? errorMessage_required : errorMessage}}
            </mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="inputType =='date' && dataType == '2'">        
        <input #input matInput [matDatepicker]="dateName" name="{{inputName}}"
        [min]="minDate" [value]="fcontrol?.value" (dateChange)="onChange($event)" [max]="endDate?.value" (click)="dateName.open()">
        <mat-datepicker-toggle matSuffix [for]="dateName">
        </mat-datepicker-toggle>
        <mat-datepicker #dateName></mat-datepicker>
        <mat-error *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </mat-error>
        <!-- <span class="error-message" *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </span> -->
    </div>
    <div *ngIf="inputType =='date' && dataType == '3'">       
        <input #input matInput [matDatepicker]="dateName" name="{{inputName}}"
        [min]="minDate" [value]="fcontrol?.value" (dateChange)="onChange($event)" [min]="startDate?.value" (click)="dateName.open()">
        <mat-datepicker-toggle matSuffix [for]="dateName">
        </mat-datepicker-toggle>
        <mat-datepicker #dateName></mat-datepicker>
       
        <!-- <span class="error-message" *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </span> -->
        <mat-error *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </mat-error>
    </div>
    <div *ngIf="inputType =='date' && dataType == '4'" >
        <mat-form-field 
            appearance="outline" 
            [ngClass]="{ 'is-invalid-form-field': formSubmit && error }"
            floatLabel="always">
            <input #input matInput class="{{classAdd}} " [matDatepicker]="dateName" name="{{inputName}}"
            [min]="minDate" [max]="maxDate"  [value]="fvalue" (dateChange)="onChange($event)" readonly (click)="dateName.open()"
            >
            <mat-datepicker-toggle matSuffix [for]="dateName"></mat-datepicker-toggle>
            <mat-datepicker #dateName></mat-datepicker>            
        </mat-form-field>        
        <mat-error *ngIf="formSubmit && error && error?.required" class="invalid-feedback-date">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </mat-error>
    </div>
    <!-- <span class="error-message" *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
        {{errorMessage_required ? errorMessage_required : errorMessage}}
    </span> -->
    <div *ngIf="inputType =='date' && dataType == '5'">
        <div class="d-flex align-items-left form-control p-0">
            <input #input matInput class="p-1 {{classAdd}} w-100" [matDatepicker]="dateName" name="{{inputName}}"
              [min]="minDate" [max]="maxDate"  [value]="fvalue" style="border : 1px solid #d8d7d7;margin : -1px; border-radius: 3px" (dateChange)="onChange($event)" (click)="dateName.open()">
            <mat-datepicker-toggle matSuffix [for]="dateName"></mat-datepicker-toggle>
            <mat-datepicker #dateName></mat-datepicker>
            <mat-error *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
                {{errorMessage_required ? errorMessage_required : errorMessage}}
            </mat-error>
        </div>
        <!-- <span class="error-message" *ngIf="(fcontrol?.touched || formSubmit) && error && error?.required">
            {{errorMessage_required ? errorMessage_required : errorMessage}}
        </span> -->
    </div>


