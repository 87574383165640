import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ReminderService } from './services/reminder.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReminderDialogComponent } from '../Dialogs/reminders/reminders.component';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { TasksService } from 'src/app/pages/tasks/services/tasks.service';

@Component({
  selector: 'app-reminder-popup',
  templateUrl: './reminder-popup.component.html'  
})
export class ReminderPopupComponent implements OnInit, OnDestroy {
  reminders: any[] = [];
  currentReminder: any = null;
  subscriptions: Subscription[] = [];
  reminderInterval: any;
  
  constructor(private reminderService: ReminderService, public dialog: MatDialog,private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getReminderListing();
    setInterval(() => {
      this.getReminderListing();
    }, 180000);

    // Call scheduleNextReminder every second
    this.reminderInterval = setInterval(() => {
      this.scheduleNextReminder();
    }, 30000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.reminderInterval) {
      clearInterval(this.reminderInterval);
    }
  }


  getReminderListing() {
    if(localStorage.getItem('token')) {
      localStorage.removeItem('reminderList');
      const formattedDate = this.getCurrentFormattedDate();
      const reminderListSubs = this.reminderService.taskList({hideSpinner: true}).subscribe(reminders => {
        const remData = reminders?.reminders || [];
        localStorage.setItem('reminderList', JSON.stringify(remData[formattedDate]));
      });
      this.subscriptions.push(reminderListSubs);
    }    
  }
  

  scheduleNextReminder() {
    const todayReminderData = [];    
    const todayDate = this.getCurrentFormattedDate();    
    if(localStorage.getItem('reminderList') && localStorage.getItem('reminderList') != 'undefined') {
      const reminderResult =  JSON.parse(localStorage.getItem('reminderList'));
      if (reminderResult) {
        todayReminderData.push(...reminderResult);
      }
      let newData = [];
      if(todayReminderData && todayReminderData.length > 0) {
        for(let l = 0;l < todayReminderData.length; l++) {
          const res = todayReminderData[l];
          let reminderTime;
          let reminderTimeAllow = false;
          const now = Date.now();
          if(res.reminder_time) {
            reminderTime = new Date(res.reminder_time).getTime();         
            reminderTimeAllow = Math.abs(reminderTime - now) < 30000;
          }
          if (( reminderTimeAllow || res.past || reminderTime < now || (!res.reminder_time || res.reminder_time == "" || res.reminder_time == null)) && res.calender_show_date <= todayDate) { // within a second            
            newData.push(res);   

            let reminderResult =  JSON.parse(localStorage.getItem('reminderList')); 
            const indexInInput = reminderResult.findIndex(item => item.uniqueId === res?.uniqueId);
            reminderResult.splice(indexInInput, 1);    
            localStorage.setItem('reminderList', JSON.stringify(reminderResult));         
          }          
        }
        if(newData && newData.length > 0) {
          this._snackBar.openFromComponent(ReminderSnackbarComponent, {
            data: newData,
            horizontalPosition: 'end',
            verticalPosition: 'top',
          }); 
        }   
      }
    }  
      // todayReminderDataPromise.then(() => {
      //   console.log(newData);
      //   this._snackBar.openFromComponent(ReminderSnackbarComponent, {
      //     data: newData,
      //     horizontalPosition: 'end',
      //     verticalPosition: 'top',
      //   });            
      //   console.log('>>>>>>>>>>>>>>>> End')
      // });    
  }

  getDurationInMinutes(durationName: string): number {
    const match = durationName.match(/(\d+)\s*min/);
    return match ? parseInt(match[1], 10) : 0;
  }


   getCurrentFormattedDate() {
    const today = new Date();
  
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0 to 11
    const day = String(today.getDate()).padStart(2, '0'); // getDate() returns the day of the month
  
    return `${year}-${month}-${day}`;
  }

  closeReminder() {
    this.currentReminder = null;
  }
}



@Component({
  selector: 'reminder-snackbar',
  templateUrl: './reminders.component.html' ,
  styleUrls: ['./reminders.component.scss']
})
export class ReminderSnackbarComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private TasksService: TasksService,public snackBarRef: MatSnackBarRef<ReminderSnackbarComponent>) { }
  subscriptions: Subscription[] = [];
  topPopup = 0;
  ngOnInit(): void {
   
  };

  closeDialog(a, item) {
    if(a == 'c') {
      const updateTaskSubs =  this.TasksService.updateTask({id: item.id, create: item}).subscribe(res => {        
        // this.dialogRef.close();
        // this.snackBarRef.dismiss();
        const indexInInput = this.data.findIndex(itemData => itemData.uniqueId === item?.uniqueId);
        this.data.splice(indexInInput, 1);        
        this.checkPendingReminders();
      });
      this.subscriptions.push(updateTaskSubs);  
      // const notificationReadSubs = this.TasksService.viewTaskList(this.data.id).subscribe(async (res: any) => {
      //   this.dialogRef.close();
      // });
      // this.subscriptions.push(notificationReadSubs);
    } 
    if(a == 'h') {      
      const updateTaskSubs =  this.TasksService.updateTask({id: item.id, mark_as_completed_user: 1, update: item}).subscribe(res => {        
        const indexInInput = this.data.findIndex(itemData => itemData.uniqueId === item?.uniqueId);        
        this.data.splice(indexInInput, 1);  
        this.checkPendingReminders();
        // this.dialogRef.close();
        // this.snackBarRef.dismiss();
      });
      this.subscriptions.push(updateTaskSubs);
    }
    this.topPopup++;    
  }
  checkPendingReminders() {
    if(this.data.length <= 0) {
      this.snackBarRef.dismiss();
    }
  }

  closePopup() {
    this.snackBarRef.dismiss();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());    
  }
}