<div style="background-color:#E3ECF1 ;">
    <div class="container-fluid pb-3">
        
        <ng-container *ngFor="let embed of embedFromData?.data;">
            <div class="box-content-st mx-auto ">
    
                <div class="box-content-elements">
    
                    <div *ngIf="embed?.name==='Heading'" class="d-flex heading-{{ headingCount }}" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
                    }">
                        <h1 class="f-responsive" [ngStyle]="{
                            'font-size':heading_Size?heading_Size+'px':embed?.font_size+'px',
                            'color': '#333',
                        'margin-bottom':'0px',
                        'font-family': selectedFont ? selectedFont :embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
                    }">{{embed?.value}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></h1>
                    </div>
    <!-- 
                    <div *ngIf="embed?.name==='Total Due'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'color': '#333',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }"> {{ embed.currency_symbol }}{{ embed.value }} {{embed.currency}}</p>
                        </div> -->
    
                        <div *ngIf="embed?.name === 'Total Due'" class="d-flex" [ngStyle]="{
                            'justify-content': embed?.align,
                            'margin-bottom': embed?.mrbottum + 'px',
                            'font-size': embed?.font_size + 'px',
                            'color': '#333',
                            'font-family': embed?.fontFamily,
                            'font-weight': embed?.font_Weight,
                            'padding-bottom': embed?.padding + 'px',
                            'padding-top': embed?.paddingT + 'px',
                            'padding-right': embed?.paddingR + 'px',
                            'padding-left': embed?.paddingL + 'px',
                            'margin-top': embed?.mrtop + 'px',
                            'margin-right': embed?.mrright + 'px',
                            'margin-left': embed?.mrleft + 'px',
                            'text-decoration': embed?.textDecoration,
                            'font-style': embed?.font_italic
                          }">
                            <p class="txt-res" [ngStyle]="{
                              'font-size': embed?.font_size + 'px',
                              'color': '#333',
                              'margin-bottom': embed?.mrbottum + 'px',
                              'font-family': embed?.fontFamily,
                              'font-weight': embed?.font_Weight,
                              'padding-bottom': embed?.padding + 'px',
                              'padding-top': embed?.paddingT + 'px',
                              'padding-right': embed?.paddingR + 'px',
                              'padding-left': embed?.paddingL + 'px',
                              'margin-top': embed?.mrtop + 'px',
                              'margin-right': embed?.mrright + 'px',
                              'margin-left': embed?.mrleft + 'px',
                              'text-decoration': embed?.textDecoration,
                              'font-style': embed?.font_italic
                            }">
                            {{ embed?.user_currency?embed?.currency_symbol:crSymbol }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed.value }}</span>
                            {{embed?.user_currency?embed?.currency:selectedCurrency?.currency_code}}
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              <!-- <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                            </p>
                          </div>
    
                          <div *ngIf="embed?.name === 'Currency'" class="d-flex" [ngStyle]="{
                            'justify-content': embed?.align,
                            'margin-bottom': embed?.mrbottum + 'px',
                            'font-size': embed?.font_size + 'px',
                            'color': '#333',
                            'font-family': embed?.fontFamily,
                            'font-weight': embed?.font_Weight,
                            'padding-bottom': embed?.padding + 'px',
                            'padding-top': embed?.paddingT + 'px',
                            'padding-right': embed?.paddingR + 'px',
                            'padding-left': embed?.paddingL + 'px',
                            'margin-top': embed?.mrtop + 'px',
                            'margin-right': embed?.mrright + 'px',
                            'margin-left': embed?.mrleft + 'px',
                            'text-decoration': embed?.textDecoration,
                            'font-style': embed?.font_italic
                          }">
                            <p class="txt-res" [ngStyle]="{
                              'font-size': embed?.font_size + 'px',
                              'color': '#333',
                              'margin-bottom': embed?.mrbottum + 'px',
                              'font-family': embed?.fontFamily,
                              'font-weight': embed?.font_Weight,
                              'padding-bottom': embed?.padding + 'px',
                              'padding-top': embed?.paddingT + 'px',
                              'padding-right': embed?.paddingR + 'px',
                              'padding-left': embed?.paddingL + 'px',
                              'margin-top': embed?.mrtop + 'px',
                              'margin-right': embed?.mrright + 'px',
                              'margin-left': embed?.mrleft + 'px',
                              'text-decoration': embed?.textDecoration,
                              'font-style': embed?.font_italic
                            }">
                            {{ embed?.user_currency?embed?.currency_symbol:crSymbol }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed.value }}</span>
                            {{embed?.user_currency?embed?.currency:selectedCurrency?.currency_code}}
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              <!-- <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                            </p>
                          </div>
                    
                    <div *ngIf="embed?.name==='Date' || embed?.name=='Forecast Date'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
    
    
                    }">
                        <h1 class="txt-res" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
    
    
                    }"> {{embed?.dateTitle}}<span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></h1>
                    </div>
    
                    <div *ngIf="embed?.name==='Text'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">
                        <p class="txt-res" [ngStyle]="{
                            'font-size':embed?.font_size+'px',
                            'color': '#333',
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                    }">{{embed?.value}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></p>
                    </div>
                    <div *ngIf="embed?.name === 'Organization N.' || embed?.name === 'Item name'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align,
                        'margin-bottom': embed?.mrbottum + 'px',
                        'font-size': embed?.font_size + 'px',
                        'color': '#333',
                        'font-family': embed?.fontFamily,
                        'font-weight': embed?.font_Weight,
                        'padding-bottom': embed?.padding + 'px',
                        'padding-top': embed?.paddingT + 'px',
                        'padding-right': embed?.paddingR + 'px',
                        'padding-left': embed?.paddingL + 'px',
                        'margin-top': embed?.mrtop + 'px',
                        'margin-right': embed?.mrright + 'px',
                        'margin-left': embed?.mrleft + 'px',
                        'text-decoration': embed?.textDecoration,
                        'font-style': embed?.font_italic
                      }">
                        <p class="txt-res" [ngStyle]="{
                          'font-size': embed?.font_size + 'px',
                          'color': '#333',
                          'margin-bottom': embed?.mrbottum + 'px',
                          'font-family': embed?.fontFamily,
                          'font-weight': embed?.font_Weight,
                          'padding-bottom': embed?.padding + 'px',
                          'padding-top': embed?.paddingT + 'px',
                          'padding-right': embed?.paddingR + 'px',
                          'padding-left': embed?.paddingL + 'px',
                          'margin-top': embed?.mrtop + 'px',
                          'margin-right': embed?.mrright + 'px',
                          'margin-left': embed?.mrleft + 'px',
                          'text-decoration': embed?.textDecoration,
                          'font-style': embed?.font_italic
                        }">
                          {{ embed?.value }}
                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                </p>
                      </div>
                      
                      <div *ngIf="embed?.name === 'Item code'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align,
                        'margin-bottom': embed?.mrbottum + 'px',
                        'font-size': embed?.font_size + 'px',
                        'color': '#333',
                        'font-family': embed?.fontFamily,
                        'font-weight': embed?.font_Weight,
                        'padding-bottom': embed?.padding + 'px',
                        'padding-top': embed?.paddingT + 'px',
                        'padding-right': embed?.paddingR + 'px',
                        'padding-left': embed?.paddingL + 'px',
                        'margin-top': embed?.mrtop + 'px',
                        'margin-right': embed?.mrright + 'px',
                        'margin-left': embed?.mrleft + 'px',
                        'text-decoration': embed?.textDecoration,
                        'font-style': embed?.font_italic
                      }">
                        <p class="txt-res" [ngStyle]="{
                          'font-size': embed?.font_size + 'px',
                          'color': '#333',
                          'margin-bottom': embed?.mrbottum + 'px',
                          'font-family': embed?.fontFamily,
                          'font-weight': embed?.font_Weight,
                          'padding-bottom': embed?.padding + 'px',
                          'padding-top': embed?.paddingT + 'px',
                          'padding-right': embed?.paddingR + 'px',
                          'padding-left': embed?.paddingL + 'px',
                          'margin-top': embed?.mrtop + 'px',
                          'margin-right': embed?.mrright + 'px',
                          'margin-left': embed?.mrleft + 'px',
                          'text-decoration': embed?.textDecoration,
                          'font-style': embed?.font_italic
                        }">
                          {{ embed?.value }}
                          <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                </p>
                      </div>
    
                      <!-- <div *ngIf="embed?.name === 'Number List'" class="d-flex" [ngStyle]="{
                        'justify-content': embed?.align
                      }">
                        <ol >
                          <li *ngFor="let listItem of numberList; let i = index">
                            <input type="text" [(ngModel)]="numberList[i]" class="borderless-input" [ngStyle]="{
                                'font-size': embed?.font_size + 'px',
                                'color': '#333',
                                'margin-bottom': embed?.mrbottum + 'px',
                                'font-family': embed?.fontFamily,
                                'font-weight': embed?.font_Weight,
                                'padding-bottom': embed?.padding + 'px',
                                'padding-top': embed?.paddingT + 'px',
                                'padding-right': embed?.paddingR + 'px',
                                'padding-left': embed?.paddingL + 'px',
                                'margin-top': embed?.mrtop + 'px',
                                'margin-right': embed?.mrright + 'px',
                                'margin-left': embed?.mrleft + 'px',
                                'text-decoration': embed?.textDecoration,
                                'font-style': embed?.font_italic
                              }">
                          </li>
                        </ol>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                      </div> -->
    
    
                      <div *ngIf="embed?.name =='Number List'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p class="txt-res" readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':'bolder',
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{ embed?.label}}
                        <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                            <div *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue">
                                <ol>
                                  <li *ngFor="let coun of embed?.custom_value?.value; let i = index" [ngStyle]="{
                                      'justify-content': embed?.align,
                                      'margin-bottom': embed?.mrbottum + 'px',
                                      'font-size': embed?.font_size + 'px',
                                      'color': '#333',
                                      'font-family': embed?.fontFamily,
                                      'padding-bottom': embed?.padding + 'px',
                                      'padding-top': embed?.paddingT + 'px',
                                      'padding-right': embed?.paddingR + 'px',
                                      'padding-left': embed?.paddingL + 'px',
                                      'color': embed?.color,
                                      'margin-top': embed?.mrtop + 'px',
                                      'margin-right': embed?.mrright + 'px',
                                      'margin-left': embed?.mrleft + 'px',
                                      'text-decoration': embed?.textDecoration,
                                      'font-style': embed?.font_italic
                                  }">
                                    <span style="font-weight: normal;">{{ coun?.name }}</span>
                                    <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }}</span>
                                  </li>
                                </ol>
                              </div>
                                                                                </ng-container>
                        <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                        <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                        
             </p>
             <br>
             <!-- <div *ngIf="embed?.custom_value?.value?.length">
                <span  [value]="coun?.name"
                *ngFor="let coun of embed?.custom_value?.value"
                [selected]="coun?.selected">{{coun?.name}}</span>
            </div> -->
                        </div>
    
                      
                        <div *ngIf="embed?.name =='Order List'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p class="txt-res" readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':'bolder',
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.label}}
                            <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                <div *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue">
                                    <ul>
                                      <li *ngFor="let coun of embed?.custom_value?.value; let i = index" [ngStyle]="{
                                          'justify-content': embed?.align,
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'font-family': embed?.fontFamily,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'color': embed?.color,
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                      }">
                                        <span style="font-weight: normal;">{{ coun?.name }}</span>
                                        <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }}</span>
                                      </li>
                                    </ul>
                                  </div>
                                                                                    </ng-container>
                            <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                            <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                            
                 </p>
                 <br>
                 <!-- <div *ngIf="embed?.custom_value?.value?.length">
                    <span  [value]="coun?.name"
                    *ngFor="let coun of embed?.custom_value?.value"
                    [selected]="coun?.selected">{{coun?.name}}</span>
                </div> -->
                            </div>
                          
                                                                  
                            <div *ngIf="embed?.name=='Table'">
                                <div style="width: 100%; overflow-x: auto;">
                                <table style="width:85%" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic

                                }">
                                    <tr >
                                      <!-- Generate table headers based on selectedColumnCount -->
                                      <th *ngFor="let headerIndex of embed?.displayedColumns; let i = index">
                                        <input [ngStyle]="{
                                        'align-items':embed?.align,
                                        'border':'none',
                                        'margin-bottom':'0px',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'font-size': embed?.font_size + 'px',
                                        'font-family': embed?.fontFamily,
                                        'font-weight': embed?.font_Weight,
                                        'width':'185px',
                                        'color':embed?.color !=='#000000'?embed?.color:tableStyleClass + ' ' + tableColorClass,
                                        'background-color':embed?.backGroundColor
                                         }" style="text-align: center; min-width: 110px !important; font-weight: bold;" type="text" [(ngModel)]="headerData[i]" (blur)="logTableData()"
                                          [(ngModel)]="headerIndex.name"  class="no-border"/>    
                               
                                </th>
                                    </tr>
                                    <tr *ngFor="let row of [].constructor(selectedRowCount); let i = index">
                                      <!-- Generate editable table cells based on selectedColumnCount -->
                                      <td *ngFor="let cellIndex of [].constructor(selectedColumnCount); let j = index">
                                        <input  type="text"  class="no-border"
                                        
                                        [ngStyle]="{
                                        'border':'none',
                                        'margin-bottom':'0px',
                                        'width':'185px'
                                        }" />
                                      </td>
                                    </tr>
                                  </table>
                                  </div>
                                  <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                              </div>

    
    
    
    
    
                    <div *ngIf="embed?.name === 'embed table'">
                        <div style="width: 100%; overflow-x: auto;">
                            <!-- <table [ngClass]="tableStyleClass + ' ' + tableColorClass" mat-table [dataSource]="data" class="mat-elevation-z8">
                                                                        <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumns11">
                                                                          <th *matHeaderCellDef>
                                                                            <div style="text-align: center; min-width: 110px !important; font-weight: bold;" *ngIf="column.orientation =='column'">{{column.name}}</div>
                                                                          </th>
                                                                          <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
                                                                        </ng-container> -->
                            <table [ngClass]="tableStyleClass + ' ' + tableColorClass" mat-table [dataSource]="data" multiTemplateDataRows
                                class="mat-elevation-z8">
                                <ng-container matColumnDef="{{column.name}}" *ngFor="let column of displayedColumns11">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div style="text-align: center; min-width: 110px !important; font-weight: bold;"
                                            *ngIf="column.orientation =='column'">{{column.name}}</div>
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element[column.name]}} </td>
                                </ng-container>
                    
                                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                <ng-container matColumnDef="expandedDetail">
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                      <div class="example-element-detail flex-column gap-2">
                                        <div *ngFor="let column of displayedColumns11" [@detailExpand]="true">
                                          <div *ngIf="column.orientation == 'row' && columnsToDisplay.includes(column.name)">
                                            {{column.name}}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </ng-container>
                                                                                                                                                                                                                                                                                                                                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                                    [class.example-expanded-row]="expandedElement === element"
                                    (click)="expandedElement = expandedElement === element ? null : element">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    
                            </table>
                        </div>
                    
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo" /></span>
                    </div>
                                                                                                                                                                                                                                                                                                          
                                                                    
                        <div *ngIf="embed?.name==='Image' || embed?.name=== 'Organization Logo ' || embed?.name === 'Workspace Logo' || embed?.name=== 'Item image'" class="d-flex"
                        [ngStyle]="{'justify-content':embed?.align,'margin-bottom':embed?.mrbottum+'px' }">
                        <img [src]="getLogo?getLogo:staticLogo" alt="logo"
                            [ngStyle]="{'width':embed?.width+'%', 'border-radius':embed?.brradius+'px'}">
                    </div>
                     <div *ngIf="embed?.name==='Divider'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <hr style="height: 2px; margin: 10px 0;"
                            [ngStyle]="{
                            'width':embed?.width+'%',
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    
                    <div *ngIf="embed?.name==='Name' || embed?.name === 'First Name' || embed?.name === 'Business Name' || embed?.name === 'Deal Title' || embed?.name === 'Commercial Name'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res"  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'color': '#333',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{ embed?.value }}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
                        <div *ngIf="embed?.name==='Last Name'"  class="d-flex txt-res" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.value }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
                            <div *ngIf="embed?.name === 'tax id' || embed?.name === 'Quote ID' || embed?.name === 'Invoice ID' || embed?.name ==='Sales Order ID'"  class="d-flex txt-res" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                            <div *ngIf="embed?.name==='Item description' || embed?.name === 'Deal Description'"  class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                                <div *ngIf="embed?.name==='Deal Stage'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item price' || embed?.name=== 'Deal Value'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item Unit' || embed?.name === 'Item quantity per click'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                                <div *ngIf="embed?.name==='Item comments'"  class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                    </div>
                            <div *ngIf="embed?.name==='Member'"  class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
    
                            <div *ngIf="embed?.name==='Email'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                </div>
                                <div *ngIf="embed?.name==='Email(s)' || embed?.name ==='Business email'" class="d-flex" [ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                        <p readonly class="txt-res"  [ngStyle]="{
                                            'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                            'margin-bottom':embed?.mrbottum+'px',
                                            'font-family':embed?.fontFamily,
                                        'font-weight':embed?.font_Weight,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                    }">{{ embed?.value }}
                                    <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                    <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                    </div>
                           
                                <div *ngIf="embed?.name === 'Phone number(s)' || embed?.name=== 'Phone Number'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed.phone_number && embed.phone_number.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.phone_number; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p class="txt-res" readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Phone'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed.phone_number && embed.phone_number.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.phone_number; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  
    
                 
                                  <div *ngIf="embed?.name === 'Address'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly class="txt-res" [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Source of contact'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ embed?.value }}{{ i + 1 +':'}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Language'" class="d-flex txt-res" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ phoneNumber?.module_name?phoneNumber?.module_name:embed?.value }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.phone_number?.length?phoneNumber?.name: embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="embed?.name === 'Contact custom fields' || embed?.name === 'Custom embed fields'" class="d-flex" [ngStyle]="{
                                    'justify-content': embed?.align,
                                    'margin-bottom': embed?.mrbottum + 'px',
                                    'font-size': embed?.font_size + 'px',
                                    'color': '#333',
                                    'font-family': embed?.fontFamily,
                                    'font-weight': embed?.font_Weight,
                                    'padding-bottom': embed?.padding + 'px',
                                    'padding-top': embed?.paddingT + 'px',
                                    'padding-right': embed?.paddingR + 'px',
                                    'padding-left': embed?.paddingL + 'px',
                                    'color': embed?.color,
                                    'margin-top': embed?.mrtop + 'px',
                                    'margin-right': embed?.mrright + 'px',
                                    'margin-left': embed?.mrleft + 'px',
                                    'text-decoration': embed?.textDecoration,
                                    'font-style': embed?.font_italic
                                  }">
                                    <ng-container *ngIf="embed?.address_title && embed?.address_title?.length > 0; else noPhoneNumbers">
                                      <ng-container *ngFor="let phoneNumber of embed.address_title; let i = index">
                                        <p readonly class="txt-res" [ngStyle]="{
                                          'font-size': embed?.font_size + 'px',
                                          'color': '#333',
                                          'margin-bottom': embed?.mrbottum + 'px',
                                          'font-family': embed?.fontFamily,
                                          'font-weight': embed?.font_Weight,
                                          'padding-bottom': embed?.padding + 'px',
                                          'padding-top': embed?.paddingT + 'px',
                                          'padding-right': embed?.paddingR + 'px',
                                          'padding-left': embed?.paddingL + 'px',
                                          'margin-top': embed?.mrtop + 'px',
                                          'margin-right': embed?.mrright + 'px',
                                          'margin-left': embed?.mrleft + 'px',
                                          'text-decoration': embed?.textDecoration,
                                          'font-style': embed?.font_italic
                                        }">
                                          {{ phoneNumber?.module_name?phoneNumber?.module_name:embed?.value}} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.address_title?.length?phoneNumber?.title:embed?.tag }}</span>
                                          <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                        </p>
                                      </ng-container>
                                    </ng-container>
                                    <ng-template #noPhoneNumbers>
                                        <p readonly [ngStyle]="{
                                            'font-size': embed?.font_size + 'px',
                                            'color': '#333',
                                            'margin-bottom': embed?.mrbottum + 'px',
                                            'font-family': embed?.fontFamily,
                                            'font-weight': embed?.font_Weight,
                                            'padding-bottom': embed?.padding + 'px',
                                            'padding-top': embed?.paddingT + 'px',
                                            'padding-right': embed?.paddingR + 'px',
                                            'padding-left': embed?.paddingL + 'px',
                                            'margin-top': embed?.mrtop + 'px',
                                            'margin-right': embed?.mrright + 'px',
                                            'margin-left': embed?.mrleft + 'px',
                                            'text-decoration': embed?.textDecoration,
                                            'font-style': embed?.font_italic
                                          }">
                                            {{ embed?.value+':' }} <span style="color: #1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.tag }}</span>
                                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                                          </p>
                                    </ng-template>
                                  </div>
    
                        <div *ngIf="embed?.name==='Website' || embed?.name === 'Video URL' || embed?.name === 'Website URL'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.value }}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.url?embed?.url:embed?.tag }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
                            <div *ngIf="embed?.name==='Social links'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                        'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly class="txt-res" [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{ embed?.value }}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.url?embed?.url:embed?.tag }}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                                </div>
                        <!-- <div *ngIf="embed?.name==='Organization N.'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="business" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="url" class="form-control" id="website" name="website"
                        <input type="text" class="form-control" id="business" name="business"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                    </div>
                  
                    </div> -->
                    <!-- <div *ngIf="embed?.name==='Organization N.'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="business" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="text" [value]="embed?.tag" readonly class="form-control" id="business" name="business"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                    </div> -->
    
    
                    <div *ngIf="embed?.name==='Company'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="company" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont
                    }">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <input type="text" class="form-control" id="company" name="company"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Industry'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label class="lebel_res" for="industry" [ngStyle]="{
                        'font-size':bodying_Size+'px',
                        'font-weight':'500',
                        'font-family':selectedFont }">{{embed?.label}} <small
                                *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont }">(Optional)</small></label>
                        <input  type="text" class="form-control lebel_res" id="industry" name="industry"
                            [placeholder]="embed?.placeholder" [ngStyle]="{
                            'font-size': bodying_Size+'px', 
                            'width': '80%',
                            'font-family':selectedFont }">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Textbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="textbox" class="lebel_res" [ngStyle]="{
                            'font-size':bodying_Size+'px',
                            'font-weight':'500',
                            'font-family':selectedFont || 'Arial, sans-serif' }">{{embed?.label}} </label>
                        <input type="text" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'width': '80%',
                    'font-family':selectedFont || 'Arial, sans-serif',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                
                }" class="form-control txt-res" id="textbox"
                            name="textbox" [placeholder]="embed?.placeholder">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                        <p class="m-0 label_res2" [ngStyle]="{
                    'font-size': help_txt_Size+'px',
                    'font-weight': '400',
                    'font-family':selectedFont || 'Arial, sans-serif',
                    'color': '#333'}">{{embed?.help_txt}}</p>
                    </div>
                    <div *ngIf="embed?.name==='Button'" class="d-flex" [ngStyle]="{
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <button class="txt-res" type="submit" [ngStyle]="{
                    'width':embed?.width+'%',
                    'background': embed?.backgroundcolor,
                    'height':embed?.height+'px',
                    'border': 'none',
                    'border-radius': '5px',
                    'font-weight': '600',
                    'font-size': bodying_Size+'px',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                        'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">{{embed?.value}}</button>
                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Selectbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label for="selectbox" class="lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{labelName}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <select class="form-select txt-res" name="selectbox" id="selectbox"
                            [ngStyle]="{'width':'80%','font-family':selectedFont}">
                            <option [value]="coun?.value" class="txt-res"
                                *ngFor="let coun of filteredCustomFieldList;"
                                [selected]="coun?.selected">{{coun?.value}}</option>
                        </select>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Textarea'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <label  for="textarea" class="lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                        'color': '#92A0A8',
                        'font-size': bodying_Size+'px',
                        'font-weight': '500',
                        'font-family':selectedFont}">(Optional)</small></label>
                        <textarea rows="3" class="form-control txt-res" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'width': '125%',
                    'font-family':selectedFont,
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                            'color': '#333',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                
                }" id="textbox" name="textbox"
                            [placeholder]="embed?.placeholder"></textarea>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                    <div *ngIf="embed?.name==='Checkbox'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                }">
                        <p class="m-0 lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }">{{embed?.label}} </p>
                        <div class="d-flex flex-wrap" style="gap: 14px;">
                            <div style="
                        display: flex;
                        align-items: center;
                        " *ngFor="let check of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                <input type="checkbox" [checked]="check?.selected"
                                    id="{{check?.name}}" name="{{check?.name}}"
                                    value="{{check?.name}}" [ngStyle]="{
                            'margin-right': '4px',
                            'margin-right': '4px',
                            'border-radius': '2px',
                            'width': '15px',
                            'flex-shrink': '0',
                            'border': '1px solid #B6C9D4',
                            'height': '15px',
                            'background': '#FFF',
                            'font-family':selectedFont}">
                                <label class="lebel_res" for="{{check?.name}}" [ngStyle]="{
                            'color': '#333',
                            'font-size': bodying_Size+'px',
                            'font-weight': '500',
                            'font-family':selectedFont}"> {{check?.name}}  <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="embed?.name==='Radio button'" class="d-flex flex-column"
                        [ngStyle]="{
                        'align-items':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px'
                }">
                        <p class="m-0 lebel_res" [ngStyle]="{
                    'font-size': bodying_Size+'px', 
                    'font-weight': '500',
                    'font-family':selectedFont }
                    ">{{embed?.label}} <small *ngIf="!embed?.required" [ngStyle]="{
                    'color': '#92A0A8',
                    'font-size': bodying_Size+'px',
                    'font-weight': '500',
                    'font-family':selectedFont}">(Optional)</small></p>
                        <div class="d-flex flex-wrap" style="gap: 14px;">
                            <div style="
                        display: flex;
                        align-items: center;
                        " *ngFor="let radio of embed?.custom_value?.type?embed?.value:embed?.custom_value?.value;">
                                <input type="radio" [checked]="radio?.selected"
                                    id="{{radio?.name}}" name="radio_button"
                                    value="{{radio?.name}}" [ngStyle]="{
                            'margin-right': '4px',
                            'margin-right': '4px',
                            'border-radius': '2px',
                            'width': '15px',
                            'flex-shrink': '0',
                            'border': '1px solid #B6C9D4',
                            'height': '15px',
                            'background': '#FFF',
                            'font-family':selectedFont,
                            'font-size':bodying_Size+'px'}">
                                <label class="lebel_res" for="{{radio?.name}}" [ngStyle]="{
                            'color': '#333',
                            'font-size': bodying_Size+'px',
                            'font-weight': '500',
                            'font-family':selectedFont}"> {{radio?.name}} <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span></label>
                            </div>
                        </div>
                    </div>
    
    
    
                    <div *ngIf="embed?.quote_currency =='Quote Currency'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.quote_currency}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
    
                    <div *ngIf="embed?.sub_total =='Subtotal'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly  [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.sub_total}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
                     <div *ngIf="embed?.discount =='Discount'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.discount}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
                        <div *ngIf="embed?.name =='Discount'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.discount}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                            </div>
    
    
    
    
                     
    
    
    
                     <div *ngIf="embed?.taxes =='Taxes' || embed?.name === 'Applicable taxes'" class="d-flex" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }">{{embed?.taxes}}
                        <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
                        <div *ngIf="embed?.name =='Tax Exempt indicator' || embed?.name === 'Country'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly class="txt-res" [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.taxes}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                            </div>
    
                     <div *ngIf="embed?.total =='Total' || embed?.name =='Total'" class="d-flex txt-res" [ngStyle]="{
                        'justify-content':embed?.align,
                        'margin-bottom':embed?.mrbottum+'px',
                        'font-size':embed?.font_size+'px',
                                'color': '#333',
                            'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'color':embed?.color,
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                    }">
                            <p readonly class="txt-res" [ngStyle]="{
                                'font-size':embed?.font_size+'px',
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-family':embed?.fontFamily,
                            'font-weight':embed?.font_Weight,
                            'padding-bottom':embed?.padding+'px',
                            'padding-top':embed?.paddingT+'px',
                            'padding-right':embed?.paddingR+'px',
                            'padding-left':embed?.paddingL+'px',
                            'margin-top':embed?.mrtop+'px',
                            'margin-right':embed?.mrright+'px',
                            'margin-left':embed?.mrleft+'px',
                            'text-decoration':embed?.textDecoration,
                            'font-style':embed?.font_italic
                        }"><span *ngIf="embed?.total =='Total'">{{embed?.total}}</span>
                        <span *ngIf="embed?.name == 'Total' && (embed?.total !=='Total' && embed?.discount !=='Discount' && embed?.due_now !=='Due Now' && embed?.taxes !=='Taxes' && embed?.additional_cost !=='Add Additional Cost' && embed?.sub_total !=='Subtotal' && embed?.quote_currency !=='Quote Currency')">please select values into properties</span>
                        <span *ngIf="embed?.name == 'Total' && (embed?.total =='Total')" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{ embed?.value }} {{ embed.user_currency ? embed.currency_symbol : crSymbol }}</span>
                        <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                              </p>
                        </div>
    
    
    
    
                        <div *ngIf="embed?.additional_cost =='Additional Field'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':'bolder',
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{ embed?.additional_cost}}
                            <ng-container *ngIf="embed?.custom_value?.value?.length > 0; else noCustomValue" style="align-items: center;">
                                <div *ngFor="let coun of embed?.custom_value?.value"[ngStyle]="{
                                    'justify-content':embed?.align,
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-size':embed?.font_size+'px',
                                            'color': '#333',
                                        'font-family':embed?.fontFamily,
                                        'padding-bottom':embed?.padding+'px',
                                        'padding-top':embed?.paddingT+'px',
                                        'padding-right':embed?.paddingR+'px',
                                        'padding-left':embed?.paddingL+'px',
                                        'color':embed?.color,
                                        'margin-top':embed?.mrtop+'px',
                                        'margin-right':embed?.mrright+'px',
                                        'margin-left':embed?.mrleft+'px',
                                        'text-decoration':embed?.textDecoration,
                                        'font-style':embed?.font_italic
                                }">
                                  <span style="font-weight: normal;" [value]="coun?.name" [selected]="coun?.selected">{{ coun?.name }}
                                </span>
                           <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
    
                                </div>
                              </ng-container>
                            <!-- <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span> -->
                            <!-- <span ><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>  -->
                            
                 </p>
                 <br>
                 <!-- <div *ngIf="embed?.custom_value?.value?.length">
                    <span  [value]="coun?.name"
                    *ngFor="let coun of embed?.custom_value?.value"
                    [selected]="coun?.selected">{{coun?.name}}</span>
                </div> -->
                            </div>
    
                            
    
    
    <!-- 
                        <div *ngIf="due_now =='Due Now'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{due_now}}
                            <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{embed?.value}}</span>
                             <span *ngIf="!embed?.tag">{{ embed?.tag }}</span> -->
                          <!-- </p>
                            </div> -->
    
    
    
                        <div *ngIf="embed?.name =='Due Now'" class="d-flex" [ngStyle]="{
                            'justify-content':embed?.align,
                            'margin-bottom':embed?.mrbottum+'px',
                            'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'color':embed?.color,
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                        }">
                                <p readonly  [ngStyle]="{
                                    'font-size':embed?.font_size+'px',
                                    'margin-bottom':embed?.mrbottum+'px',
                                    'font-family':embed?.fontFamily,
                                'font-weight':embed?.font_Weight,
                                'padding-bottom':embed?.padding+'px',
                                'padding-top':embed?.paddingT+'px',
                                'padding-right':embed?.paddingR+'px',
                                'padding-left':embed?.paddingL+'px',
                                'margin-top':embed?.mrtop+'px',
                                'margin-right':embed?.mrright+'px',
                                'margin-left':embed?.mrleft+'px',
                                'text-decoration':embed?.textDecoration,
                                'font-style':embed?.font_italic
                            }">{{embed?.label}}
                            <span *ngIf="!embed?.user_currency" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + crSymbol}}</span>
                            <span  *ngIf="embed?.user_currency" style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0 + embed?.currency_symbol}}</span>
    
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                  </p>
                            </div>
    
    
                            <div *ngIf="embed?.name =='Additional Field'" class="d-flex" [ngStyle]="{
                                'justify-content':embed?.align,
                                'margin-bottom':embed?.mrbottum+'px',
                                'font-size':embed?.font_size+'px',
                                    'color': '#333',
                                    'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'color':embed?.color,
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                            }">
                                    <p readonly  [ngStyle]="{
                                        'font-size':embed?.font_size+'px',
                                        'margin-bottom':embed?.mrbottum+'px',
                                        'font-family':embed?.fontFamily,
                                    'font-weight':embed?.font_Weight,
                                    'padding-bottom':embed?.padding+'px',
                                    'padding-top':embed?.paddingT+'px',
                                    'padding-right':embed?.paddingR+'px',
                                    'padding-left':embed?.paddingL+'px',
                                    'margin-top':embed?.mrtop+'px',
                                    'margin-right':embed?.mrright+'px',
                                    'margin-left':embed?.mrleft+'px',
                                    'text-decoration':embed?.textDecoration,
                                    'font-style':embed?.font_italic
                                }">{{embed?.label}}
                                <span style="color:#1b8ef8" [matTooltip]="'This Value Will Dynamically change.'">{{0}}</span>
                                <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>                                                      </p>
                                </div>
    
    
    
    
    
    
    
    
    
                    <div *ngIf="embed?.name==='Attachment'" class="d-flex flex-column" [ngStyle]="{
                    'align-items':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px'
                    }">
                        <p class="m-0 txt-res" [ngStyle]="{
                    'width': '80%',
                    'justify-content':embed?.align,
                    'margin-bottom':embed?.mrbottum+'px',
                    'font-size':embed?.font_size+'px',
                        'font-family':embed?.fontFamily,
                        'font-weight':embed?.font_Weight,
                        'padding-bottom':embed?.padding+'px',
                        'padding-top':embed?.paddingT+'px',
                        'padding-right':embed?.paddingR+'px',
                        'padding-left':embed?.paddingL+'px',
                        'color':embed?.color,
                        'margin-top':embed?.mrtop+'px',
                        'margin-right':embed?.mrright+'px',
                        'margin-left':embed?.mrleft+'px',
                        'text-decoration':embed?.textDecoration,
                        'font-style':embed?.font_italic
                }">{{embed?.label}} 
                        </p>
                        <label for="attachment" class="attachment-label" [ngStyle]="{
                    'display': 'flex',
                    'border-radius': '5px',
                    'border': '1px dashed #C1C1C1',
                    'padding': bodying_Size+'px',
                    'background': '#F4F4F4',
                    'margin-top': '10px',
                    'justify-content': 'center',
                    'font-family':selectedFont }">
                            <img src="assets/svg-icons/attachment_icon.svg"
                                alt="attachment_icon">
                            <div class="lebel_res" [ngStyle]="{
                        'display': 'flex',
                        'align-items': 'center',
                        'flex-direction': 'column',
                        'margin-left': '8px',
                        'font-family':selectedFont,
                        'font-size':bodying_Size+'px' }">
                                <p class="m-0"><b>Click to upload</b> or drag and drop</p>
                                <p class="m-0">
                                    <span *ngFor="let file of embed?.value">
                                        <span *ngIf="file?.value">{{file?.name}},</span>
                                    </span>(MAX: 10 MB)
                                </p>
                            </div>
                        </label>
                        <input type="file" class="form-control" style="display: none;"
                            id="attachment" name="attachment">
                            <span *ngIf="embed?.hidden"><img [src]="'assets/svg-icons/eye-hidden.svg'" alt="logo"/></span>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <div class="preview-wrapper" *ngIf="embedFromData1">
          <div class="flex justify-end col-10">
            <i (click)="closeDialog()" type="button" data-bs-dismiss="modal" aria-label="Close"
              class="color-4 fs-2 far fa-times-circle m-3"></i>
          </div>
          <div class="preview-temp">
            <ng-container>
              
              <div class="box-content-st mx-auto " #previewContent>
      
                <div class="box-content-elements">
                  <div [innerHtml]="getSafeHtml()"></div>
                </div>
              </div>
            </ng-container>
           
          </div>
        </div>
        
                
    </div>
    </div>