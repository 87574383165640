import { Component, OnInit, ViewChild, HostBinding, EventEmitter, Output, Input, Inject, OnDestroy } from '@angular/core';
import { ItemsService } from '../services/items.service';
import { SubCategoryAddModel } from '../model/sub-category-add-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
@Component({
  selector: 'add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.scss']
})

export class AddSubCategoryComponent implements OnInit, OnDestroy {
  @HostBinding('class') componentCssClass: any;
  @ViewChild('closebutton') closebutton;

  categoryList: any;
  categoryId:any
  name:any
  subcateogryFormInvalid:boolean=false
  @Output() getSubCategoryList: EventEmitter<any> = new EventEmitter<any>();

  subCategoryAddModel = new SubCategoryAddModel();
  subscriptions: Subscription[] = [];

  constructor(
    private spinnerService: NgxSpinnerService,
    private itemsService: ItemsService,
    public dialogRef: MatDialogRef<AddSubCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddSubCategoryComponent
  ) {
    this.categoryList = data?.categoryList
    this.categoryId = data?.categoryId
  }
  ngOnInit(): void {
  this.subCategoryAddModel.name=this.data?.name
  }
  closeDialog(){
    this.dialogRef.close(false)
  }
  addSubCateogry(subcateogryForm) {
    if(subcateogryForm.form.invalid){
    this.subcateogryFormInvalid=true
    }
    if (subcateogryForm.form.valid) {
      if(this.categoryId) this.subCategoryAddModel.categoryId = this.categoryId

    const addSubCateogrySubs = this.itemsService.addSubCateogry({categoryId:this.categoryId || this.subCategoryAddModel.categoryId,name:this.subCategoryAddModel.name}).subscribe(
        res => {
          if (res.message === 'success' || res.status === 1) {
            this.closebutton.nativeElement.click();
            this.getSubCategoryList.emit();
            this.spinnerService.hide();
            this.dialogRef.close(true)
          } else {
            this.spinnerService.hide();
          }
        });
      this.subscriptions.push(addSubCateogrySubs);
    }
  }

  updateSubCateogry(subcateogryForm) {
    if(subcateogryForm.form.invalid){
    this.subcateogryFormInvalid=true
    }
    if (subcateogryForm.form.valid) {
     this.data.name=subcateogryForm.form.value.name
    const addSubCateogrySubs = this.itemsService.updateItemsSubCateogry(this.data).subscribe(
        res => {
          if (res.message === 'success' || res.status === 1) {
            this.closebutton.nativeElement.click();
            this.getSubCategoryList.emit();
            this.spinnerService.hide();
            this.dialogRef.close(res)
          } else {
            this.spinnerService.hide();
          }
        });
      this.subscriptions.push(addSubCateogrySubs);
    }
  }

  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
