import { PageEvent } from '@angular/material/paginator';
import { ChangeDetectorRef,Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContactService } from 'src/app/pages/contacts/services/contact.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryAddModel } from 'src/app/pages/items/model/category-add-model';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';
import { TranslateService } from '@ngx-translate/core';
import { NewTmpComponent } from 'src/app/quote-template-new/new-tmp/new-tmp.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { DatePipe } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-clone-quote',
  templateUrl: './clone-quote.component.html',
  styleUrls: ['./clone-quote.component.scss'],
})
export class CloneQuoteComponent implements OnInit {
  @ViewChild('selectDropdown') selectDropdown!: MatSelect;
  @ViewChild('searchInput') searchInput!: ElementRef;
  selectedContact: any;
  selectedOption = 'sameContact';
  allQuoteStagesList:any=[]; 
  serchArr: any = {};
  page: any = {
    currentPage: 1,
    pageSize: 10,
    // numberOfPages: 4,
  };
  limit:any;
  itemCount = 10;
  sortField: any = 'created_at';
  sortOrder: any = 'DESC';
  globalSearchText = '';
  currentPage:any;
  where: any = [];
  merContactListArr:any;
  subscriptions: Subscription[] = [];
  resetSearch = false;
  timeout: any = null;
  originalMerContactListArr: any[];
  itemActivePagination = Array();
  openBulkAction: boolean = false;
  isEditing: boolean = false;
  categoryAddModel = new CategoryAddModel();
  public imageUrl = environment.IMAGE_BACKET_URL;
  uploadAttachmentUrl = 'https://osmos-v2-images.s3.us-west-2.amazonaws.com/upload_note_attachment/';

  constructor(
    public dialogRef: MatDialogRef<CloneQuoteComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private contactService: ContactService,
    private spinnerService: NgxSpinnerService,
    private snackbar: MatSnackBar,
    public QuoteTemplateServices: QuoteTemplateService,
    private cdr: ChangeDetectorRef,
    private translateService : TranslateService,
    public dialog: MatDialog, 
    private datePipe: DatePipe

  ) {}

  ngOnInit(): void {
   
    this.inputData;
    console.log(this.inputData?.data,'aaaaaaaaaaaa')
    if(this.inputData.type == 'stages'){
      this.getQuoteStages()
    } else if(this.inputData.type !=='invoice_info' && this.inputData.type !== 'stages') {
      this.getMerchantCustomer();
    }
  }
async getQuoteStages(){
  this.spinnerService.show()
   this.QuoteTemplateServices.get_Quote_Stages().subscribe({
    next:(resp)=>{
      this.spinnerService.hide()
      if(resp){
        this.allQuoteStagesList=resp?.data
        this.allQuoteStagesList=this.allQuoteStagesList.sort((a, b) => a?.order - b?.order);
        // if(!this.update_id){
        //   const pendingItem = this.allQuoteStagesList.find(item => item.name === 'Pending');
        //   this.setDisplayStages(pendingItem);
  
        // }
        this.cdr.detectChanges();
      }
    },
    error:(err)=>{
      console.log("got error : ",err);
    }
  })
}
  closeModal() {
    this.dialogRef.close(null);
  }

  cloneQuote() {
    if(this.selectedOption=='sameContact'){
      this.dialogRef.close({ contact: "same"});
    }
    else {
      if(!this.selectedContact){
        this.snackbar.open('Please Select Contact', 'OK')
        return;
      }
      this.dialogRef.close({ contact: this.selectedContact});
    }
  }

  onSelectionChange(event) {
    this.selectDropdown.openedChange.subscribe((opened) => {
      if (opened) {
        setTimeout(() => {
          this.searchInput.nativeElement.focus();
        });
      }
    });
  }

  onKeySearch(event: any) {
    this.resetSearch = true;
    clearTimeout(this.timeout);
    let $this = this;
    this.timeout = setTimeout(function () {
      $this.serachUserData(event.target.value);
    }, 800);
  }

  private serachUserData(value) {
    this.globalSearchText = value;
    this.getMerchantCustomer();
  }

  async getMerchantCustomer(limit: any = '') {
    $('#multipleCheck').prop('checked', false);
    this.spinnerService.show();
    if (limit != '') {
      console.log('limit>>>>',limit)
      this.serchArr = {
        pagination: true,
        limit: limit,
        // page: this.page?.currentPage || 1,
        whereFilterData: this.where,
        search: this.globalSearchText ? this.globalSearchText : '',
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      };
      await this.contactService
        .getCustomer(this.serchArr)
        .subscribe((result) => {
          if (result.create == false) {
          } else {
          }
         
        });
    } else {
      this.serchArr = {
        pagination: true,
        limit: this.page?.pageSize || this.itemCount,
        page: this.page?.currentPage || 1,
        whereFilterData: this.where,
        search: this.globalSearchText ? this.globalSearchText : '',
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      };

      const getCustomerSubs = this.contactService
        .getCustomer(this.serchArr)
        .subscribe((result) => {
          if (result.create == false) {
          } else {
          }
          this.spinnerService.hide();
        this.currentPage = result.currentPage
       this.originalMerContactListArr = result.data.sort((a, b) => {
        // Convert both firstnames to lowercase to ensure case-insensitive sorting
        const firstnameA = a.firstname.toLowerCase();
        const firstnameB = b.firstname.toLowerCase();
        // Compare the firstnames
        if (firstnameA < firstnameB) {
          return -1;
        }
        if (firstnameA > firstnameB) {
          return 1;
        }
        // If firstnames are equal, no need to change order
        return 0;
      });;
       this.merContactListArr = result.data.sort((a, b) => {
        // Convert both firstnames to lowercase to ensure case-insensitive sorting
        const firstnameA = a.firstname.toLowerCase();
        const firstnameB = b.firstname.toLowerCase();
        // Compare the firstnames
        if (firstnameA < firstnameB) {
          return -1;
        }
        if (firstnameA > firstnameB) {
          return 1;
        }
        // If firstnames are equal, no need to change order
        return 0;
      })
         this.page = {
            ...this.page,
            numberOfPages: result?.totalPages,
            totalItems: result?.totalCount,
            currentPage: result?.currentPage,
          };
          this.limit = result?.totalCount;
          this.itemActivePagination = new Array(result.totalPages);
          this.openBulkAction = false;
        });
      this.subscriptions.push(getCustomerSubs);
    }
  }

  loadAllOnScroll(page) {
    this.serchArr = {
      pagination: true,
      limit: this.page?.pageSize,
      page: page + 1 || 1,
      whereFilterData: this.where,
      search: this.globalSearchText ? this.globalSearchText : '',
      sortField: this.sortField,
      sortOrder: this.sortOrder,
    };

    const getCustomerSubs = this.contactService
      .getCustomer(this.serchArr)
      .subscribe((result) => {
        if (result) {
          this.currentPage = result.currentPage;

          this.merContactListArr = [...this.merContactListArr, ...result.data];
          this.merContactListArr.sort((a, b) => {
            const firstnameA = a.firstname.toLowerCase();
            const firstnameB = b.firstname.toLowerCase();
            if (firstnameA < firstnameB) {
              return -1;
            }
            if (firstnameA > firstnameB) {
              return 1;
            }
            return 0;
          });
        }
      });

    this.subscriptions.push(getCustomerSubs);
  }

  getImage(image,type=''){
    if (image) {
        if (type =='contact'){
          return this.imageUrl + image;
        }else{
          return this.uploadAttachmentUrl + image;
  
        }
      }
      else {
        return 'assets/img/home/user.png'
      }
  }
  getTranslatedText(key: string): string {
    const translation = this.translateService.instant(key);
    return translation !== key ? translation : '';
  }
  editQuoteStage(item){
    this.isEditing = true;
    const dialogData: any = { data:item, type: 'Quote stage' };
    // this.selectStageControl =this.currentStage?.name;
    
    const dialogRef = this.dialog.open(NewTmpComponent, {
      panelClass: 'bg_white',
      width: '670px',
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if(dialogResult){
        if(dialogResult == 'delete')
        {
          this.getQuoteStages();
          this.cdr.detectChanges();
        }else{
          this.QuoteTemplateServices.update_Quote_Stage(dialogResult).subscribe({
            next:(resp)=>{
              this.getQuoteStages();
              this.cdr.detectChanges();
    
            }
          })
        }
        
      
      }
    });
  }
  openDialogAddQuoteStage() {
    const dialogData: any = { title: 'Clone Quote Form', type: 'Quote stage' };
    const dialogRef = this.dialog.open(NewTmpComponent, {
      panelClass: 'bg_white',
      width: '670px',
      data: dialogData,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if(dialogResult){
        dialogResult.order=this.allQuoteStagesList?.length+1;
        this.QuoteTemplateServices.create_Quote_Stage([dialogResult]).subscribe({
          next:(resp)=>{
            this.getQuoteStages();
            this.cdr.detectChanges();
          }
        })
      }
    });
  }
  
  dropStages(event: CdkDragDrop<any[]>) {
    // Reorder the array of options based on drag and drop event
    const draggedItem = this.allQuoteStagesList[event.previousIndex];
    this.allQuoteStagesList.splice(event.previousIndex, 1);
    this.allQuoteStagesList.splice(event.currentIndex, 0, draggedItem);
    
    // Update the order property as numbers
    this.allQuoteStagesList.forEach((item, index) => {
      item.order = index + 1;
    });
  this.spinnerService.hide()
    this.QuoteTemplateServices.update_Quote_Stage_orders(this.allQuoteStagesList).subscribe({
      next:(resp)=>{
        this.spinnerService.hide()
        this.getQuoteStages();
        this.cdr.detectChanges();
      }
    })

  }

  highlightMatch(value: string): string {
    if (!value || !this.globalSearchText) {
      return value;
    }
  
    const searchTerm = this.globalSearchText.toLowerCase();
    const startIndex = value.toLowerCase().indexOf(searchTerm);
  
    if (startIndex !== -1) {
      const matchingPart = value.substr(startIndex, searchTerm.length);
      const beforeMatch = value.substr(0, startIndex);
      const afterMatch = value.substr(startIndex + searchTerm.length);
  
      return `${beforeMatch}<span class="highlight">${matchingPart}</span>${afterMatch}`;
    }
  
    return value;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  formatTimestamp(isoTimestamp: string): any {
    // Parse ISO timestamp
    if(isoTimestamp){
      const date = new Date(isoTimestamp);
  
      // Create the custom format
      const formattedDate = this.datePipe.transform(date, 'EEE d MMM, y');
    
      return formattedDate;
  
    }
  }
}

