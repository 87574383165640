import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ModalModel } from '../modal/modal.component';
import { ContactService } from 'src/app/pages/contacts/services/contact.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { LeadService } from 'src/app/pages/lead/services/lead.service';
import { LeadFilterModel } from 'src/app/pages/lead/all-leads/model/lead-filter-model';
import { QuoteTemplateService } from 'src/app/quote-template-new/services/quote-template.service';

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  title: string;
  message: string;
  filterSaveForm: FormGroup;
  savefiltercolor: any;
  filterSaveSubmit = false;
  filterListingData: any = {};
  filterData: any = {};
  filterListing: any = [];
  selectedactivity: any;
  where: any;
  itemCount = 10;
  globalSearchText: any = '';
  sortField: any = 'created_at';
  sortOrder: any = 'DESC';
  merLeadArrPagination: any;
  itemActivePagination = Array();
  customerFilter: boolean = false;
  selectedCustomerId: any;
  @ViewChild('closeAddFilter') closeAddFilter;
  filterModel = new LeadFilterModel;
  fromFilter: any;
  viewData: any;

  constructor(public dialogRef: MatDialogRef<FilterComponent>,
    public contactService: ContactService,
    public leadService: LeadService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public quoteTemplateService: QuoteTemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.filterBasicInfoFormVal(); this.fromFilter = this.data?.type;
  }

  saveFilter() {
    this.data = localStorage.getItem('leadAppliedFilter') ? JSON.parse(localStorage.getItem('leadAppliedFilter'))[0] : this.data;
    if(this.data?.type =='quoteListing'){
      if(this.filterSaveForm.invalid){
        return
      }
      this.savefiltercolor = true;
      this.filterSaveSubmit = true;
      let payload={
        title:this.filterSaveForm?.value?.title,
        filters:JSON.stringify(this.data?.savedFilter)
      }
      this.quoteTemplateService.createQuoteFilter(payload).subscribe((res) => {
        if(res){
          this.filterSaveForm.reset();
          this.filterSaveSubmit = false;
          this.dialogRef.close(true);
        }
      })
    }else{
      this.savefiltercolor = true;
      this.filterSaveSubmit = true;
      this.leadService.viewFilter$.subscribe((res: any) => {
        if (res) {
          this.viewData = res;
        }
      })
      if (this.filterSaveForm.invalid) {
        return;
      }else{this.saveFiltermodel();}
    }
    
   
  }

  saveFiltermodel() {
    const filterDataSet = {
      title: this.filterSaveForm?.value?.title,
      deal_type_id: this.data?.deal_type?.map(at => at?.id),
      deal_stage_id: this.data?.deal_stage?.map(at => at?.id),
      activity_id: this.data?.activity?.map(at => at?.id),
      tag_id: this.data?.tags?.map(at => at?.name),
      single_value: this.data?.singale_value,
      slider_value: this.data?.slider_value,
      singleDate: this.data?.singale_date,
      from_date: this.data?.start_date,
      to_date: this.data?.end_date,
      show_deactivated_leads:this.data?.show_deactivated_leads?this.data?.show_deactivated_leads:0,
      show_rotten_deals:this.data?.show_rotten_deals?this.data?.show_rotten_deals:0,
    };
    const filterDataSet_name = {
      title: this.filterSaveForm?.value?.title,
      deal_type_id: this.data?.deal_type?.map(at => at?.name),
      deal_stage_id: this.data?.deal_stage?.map(at => at?.name),
      activity_id: this.data?.activity?.map(at => at?.name),
      tag_id: this.data?.tags?.map(at => at?.name),
      single_value: this.data?.singale_value,
      slider_value: this.data?.slider_value,
      singleDate: this.data?.singale_date,
      from_date: this.data?.start_date,
      to_date: this.data?.end_date,
      show_deactivated_leads:this.data?.show_deactivated_leads?this.data?.show_deactivated_leads:0,
      show_rotten_deals:this.data?.show_rotten_deals?this.data?.show_rotten_deals:0,
    };
    this.filterData = { module:this.viewData, filter: filterDataSet, title: this.filterSaveForm?.value?.title, id: this.filterListingData?.id, filter_value_name: filterDataSet_name };
    this.contactService.createUpdateFilterListing(this.filterData).subscribe((res) => {
      if (res) {
        this.filterSaveForm.reset();
        this.filterSaveSubmit = false;
        this.dialogRef.close(true);
        this.leadService.filtermodule$.next(res);
      }
    }, (error) => { });
  }

  filterBasicInfoFormVal() { this.filterSaveForm = this.formBuilder.group({ title: ['', Validators.required], }) };
  onDismiss(): void { this.dialogRef.close(false); };
  closeModel(type) { this.dialogRef.close(type); };

}
