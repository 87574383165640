import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditContactService {
  BASE_URL: string;

  constructor(private http: HttpClient) {
    this.BASE_URL = environment.API_URL;
  }


  addContact(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchantcustomer', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addCustomField(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customercustomfield', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateCustomField(id, data: any = ''): Observable<any> {
    return this.http.put(this.BASE_URL + '/customercustomfield', { ...data, id })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  addPackage(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customerpackage', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updatePackage(id, data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customerpackage/edit', { ...data, id })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addClientTypes(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/clienttypes', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  addformOfContactTypes(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/formcontacts', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  deleteformOfContactTypes(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/formcontacts/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteformOfClientTypes(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/clienttypes/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteRoleForm(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/merchantrole/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  addRole(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchantrole', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }


  editContact(data: any = ''): Observable<any> {
    return this.http.patch(this.BASE_URL + '/merchantcustomer', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  updateOrganizationMember(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/merchantorganization', data)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  viewContact(id): Observable<any> {
    return this.http.get(this.BASE_URL + '/merchantcustomer/' + id)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  industriesList(resource = '') {
    return this.http.post(this.BASE_URL + '/industry/list', resource)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  socialLinks(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/sociallinks/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }


  getLanguage(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/language/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  // getAudience(data: any = ''): Observable<any> {
  //   return this.http.post('../../assets/json/audience.json', data).pipe(
  //     tap(
  //       data => data,
  //       error => error
  //     )
  //   );
  // }

  getAudience(): Observable<any> {
    return this.http.get('../../assets/json/audience.json')
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getPrice(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/prices/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  phoneCode(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/phonecode/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }



  countryList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/country/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  formContactList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/formcontacts/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  clientTypesList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/clienttypes/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  customFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customfield/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  customerCustomFieldList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/customercustomfield/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  citiesList(data: any = ''): Observable<any> {
    return this.http.post(this.BASE_URL + '/cities/list', data).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err: any) => {
        return null;
      })
    );
  }

  roleList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantrole/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deleteCustomField(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/customercustomfield/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  deletePackage(id: any = ''): Observable<any> {
    return this.http.delete(`${this.BASE_URL}/customerpackage/delete/` + id).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

  updateCustomeOrder(data: any = ''): Observable<any> {
    return this.http.put(this.BASE_URL + '/customercustomfield/order', { data })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err: any) => {
          return null;
        })
      );
  }

  getOrganizationList(data: any = ''): Observable<any> {
    return this.http.post(`${this.BASE_URL}/merchantorganization/list`, data).pipe(
      tap(
        data => data,
        error => error
      )
    );
  }

}
